import { render, staticRenderFns } from "./CUpload.vue?vue&type=template&id=dab95778"
import script from "./CUpload.vue?vue&type=script&lang=js"
export * from "./CUpload.vue?vue&type=script&lang=js"
import style0 from "./CUpload.vue?vue&type=style&index=0&id=dab95778&lang=sass"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\NewDevelopment\\SHMS\\FrontEnd\\PSMFrontEnd\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dab95778')) {
      api.createRecord('dab95778', component.options)
    } else {
      api.reload('dab95778', component.options)
    }
    module.hot.accept("./CUpload.vue?vue&type=template&id=dab95778", function () {
      api.rerender('dab95778', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/CUpload.vue"
export default component.exports