<!--
  TODO : QR 코드 버튼 공통 모듈
-->
<template>
  <!-- QR 코드 -->
  <q-btn 
    :class="[btnclass ? btnclass : '', 'custom-btn']" 
    label="QR 코드" 
    :disable="disabled"
    :loading="loading"
    :outline="outline"
    :size="size"
    icon="qr_code_scanner" 
    color="black">
    <q-popup-proxy>
      <span action="#" id="printJS-form">
        <vue-qrcode
          :value="serverName+mobileUrl" 
          :options="{ width: 180 }"
          class="canvas"
        >
        </vue-qrcode>
      </span>
      <q-btn v-if="isPrint" label="" color="grey" size="xs" icon="print" @click.prevent="print" />
      <slot name="detail"></slot>
      <!-- <c-card title="QR 코드 출력" class="cardClassDetailForm qrMargin">
        <template slot="card-button">
          <c-btn v-if="isPrint" label="인쇄" icon="print" @btnClicked="print" />
        </template>
        <template slot="card-detail">
          <div class="wrap" action="#" id="printJS-form">
            <vue-qrcode
              :value="serverName+mobileUrl" 
              :options="{ width: 180 }"
              class="canvas"
            >
            </vue-qrcode>
            <slot name="detail"></slot> -->
            <!-- <span class="qrProxy">
              <p class="qrProxyDetail" >
              </p>
            </span> -->
          <!-- </div>
        </template>
      </c-card> -->
    </q-popup-proxy>
    <slot name="tooltip"></slot>
  </q-btn>
</template>

<script>
import VueQrcode from '@chenfengyuan/vue-qrcode';
import printJs from 'print-js'
import mixinCommon from './js/mixin-common'
export default {
  /* attributes: name, components, props, data */
  name: 'c-qr-btn',
  components: {
    VueQrcode
  },
  mixins: [mixinCommon],
  props: {
    btnclass: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      defualt: 'md'
    },
    mobileUrl: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isPrint: {
      type: Boolean,
      default: true,
    },
    showLoading: {
      type: Boolean,
      default: true,
    },
    outline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      serverName: '',
    };
  },
  computed: {
  },
  watch: {
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  mounted() {
    this.serverName = this.$store.getters.serverUrl
  },
  /* methods */
  methods: {
    print() {
      printJs({
        printable: 'printJS-form',
        type: 'html',
        font_size: '20px',
        targetStyles: ['*'],
      })
    },
  },
};
</script>

<style scoped>
/* .qrProxy {
  height:200px;
  width:300px;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-wrap:wrap;
}
.qrProxyDetail {
  width:100%;
  height:20px;
  display:inline;
} */
.wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 20px 20px 0px;
  width: 220px;
  height: 125px;
  box-sizing: border-box;
  margin-bottom: 121px; 
}
</style>

<style lang="sass">
.qrMargin 
  .customCardbody
    margin-bottom: 0px !important

</style>