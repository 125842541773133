var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-table", {
        directives: [{ name: "table-resizable", rawName: "v-table-resizable" }],
        key: _vm.tableKey,
        ref: "compo-table",
        staticClass: "default-table",
        class: [
          _vm.topBorderClass,
          _vm.isDashboard ? "isDashboardTable" : "",
          _vm.isDense ? "default-table-no-padding" : "",
          _vm.expanded ? "" : "fold-table",
        ],
        style: { height: _vm.expanded ? _vm.height : "50px" },
        attrs: {
          tableId: _vm.tableId,
          "card-class": _vm.cardClass,
          flat: "",
          bordered: "",
          grid: _vm.grid,
          fullscreen: _vm.fullscreen,
          separator: _vm.separator,
          data: _vm.data,
          columns: _vm.arrangColumns,
          "row-key": _vm.rowKey,
          selection: _vm.selection,
          selected: _vm.selected,
          "visible-columns": _vm.visibleColumns,
          filter: _vm.filter,
          dense: _vm.dense,
          loading: _vm.loading,
          "no-data-label": _vm.noDataLabel,
          "no-results-label": _vm.noResultLabel,
          pagination: _vm.initialPagination,
          "hide-pagination": false,
          "virtual-scroll": !_vm.usePaging,
          "virtual-scroll-slice-size": !_vm.usePaging ? 20 : 20,
          "virtual-scroll-item-size": !_vm.usePaging ? 48 : 0,
          "virtual-scroll-sticky-size-start": !_vm.usePaging ? 48 : 0,
          "hide-bottom": _vm.hideBottom,
          "hide-header": _vm.hideHeader,
        },
        on: {
          "update:selected": function ($event) {
            _vm.selected = $event
          },
          "update:pagination": function ($event) {
            _vm.initialPagination = $event
          },
          selection: _vm.changeSelection,
          "virtual-scroll": _vm.virtualScroll,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "loading",
              fn: function () {
                return [
                  _c("q-inner-loading", {
                    attrs: { showing: "", color: "primary" },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "no-data",
              fn: function ({ message }) {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "full-width row flex-center text-accent q-gutter-sm gridNodataDiv",
                    },
                    [
                      _c("q-icon", {
                        attrs: { size: "2em", name: "sentiment_dissatisfied" },
                      }),
                      _c("span", [_vm._v(" " + _vm._s(message) + " ")]),
                    ],
                    1
                  ),
                ]
              },
            },
            _vm.isTop
              ? {
                  key: "top",
                  fn: function () {
                    return [
                      _vm.isTitle
                        ? [
                            _c(
                              "div",
                              { staticClass: "float-left" },
                              [
                                _vm._t("perfixTitle"),
                                _c(
                                  "div",
                                  { staticClass: "q-table__title" },
                                  [
                                    _c("i", {
                                      staticClass: "pe-7s-angle-right-circle",
                                    }),
                                    _vm._v(
                                      " " + _vm._s(_vm.convertTitle) + " "
                                    ),
                                    _vm._t("suffixTitle"),
                                  ],
                                  2
                                ),
                              ],
                              2
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "float-left",
                                class: [
                                  { gridSearchIcon: _vm.isTitle },
                                  { gridSearchIcon2: !_vm.isTitle },
                                ],
                              },
                              [
                                _vm.filtering
                                  ? _c("q-input", {
                                      staticClass: "filtering-text",
                                      staticStyle: {
                                        "min-width": "150px",
                                        "max-width": "250px",
                                      },
                                      attrs: {
                                        dense: "",
                                        debounce: "300",
                                        color: "white",
                                        placeholder: "Search",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "append",
                                            fn: function () {
                                              return [
                                                _c("q-icon", {
                                                  attrs: { name: "search" },
                                                }),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        4009527860
                                      ),
                                      model: {
                                        value: _vm.filter,
                                        callback: function ($$v) {
                                          _vm.filter = $$v
                                        },
                                        expression: "filter",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._t("customFilter"),
                              ],
                              2
                            ),
                            _c(
                              "div",
                              { staticClass: "table-chip-div" },
                              [_vm._t("table-chip")],
                              2
                            ),
                            _c("q-space"),
                            _c(
                              "div",
                              { staticClass: "col-auto cardselectarea" },
                              [_vm._t("table-header-append")],
                              2
                            ),
                            _c(
                              "div",
                              { staticClass: "q-mt-md gridbtntop" },
                              [_vm._t("table-button")],
                              2
                            ),
                            _c(
                              "div",
                              { staticClass: "float-right" },
                              [
                                _vm._t("prefixContent"),
                                _vm.isExcelDown && _vm.data.length > 0
                                  ? _c(
                                      "q-btn",
                                      {
                                        staticClass: "tableExcelIcon",
                                        attrs: { flat: "", dense: "" },
                                        on: { click: _vm.downloadExcelHtml },
                                      },
                                      [
                                        _c(
                                          "q-tooltip",
                                          {
                                            attrs: {
                                              anchor: "top left",
                                              self: "center left",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$language("엑셀 다운로드")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.isFullScreen
                                  ? _c(
                                      "q-btn",
                                      {
                                        staticClass:
                                          "tabletopicon tablefullscreen",
                                        attrs: {
                                          flat: "",
                                          dense: "",
                                          color: "grey-6",
                                          icon: _vm.fullscreen
                                            ? "fullscreen_exit"
                                            : "fullscreen",
                                        },
                                        on: { click: _vm.clickFullScreen },
                                      },
                                      [
                                        _vm.fullscreen
                                          ? _c("q-tooltip", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$language("원래대로")
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _c(
                                              "q-tooltip",
                                              {
                                                attrs: {
                                                  anchor: "top left",
                                                  self: "center left",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$language("전체화면")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.columnSetting
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "table-setting gridsettingIcon",
                                      },
                                      [
                                        _c(
                                          "q-btn",
                                          {
                                            staticClass: "tabletopicon",
                                            attrs: {
                                              flat: "",
                                              dense: "",
                                              color: "grey-6",
                                              icon: "more_vert",
                                            },
                                          },
                                          [
                                            _c("q-tooltip", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$language("컬럼설정")
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _c(
                                              "q-menu",
                                              {
                                                attrs: {
                                                  "transition-show":
                                                    "jump-down",
                                                  "transition-hide": "jump-up",
                                                },
                                              },
                                              [
                                                _c(
                                                  "q-list",
                                                  {
                                                    staticClass:
                                                      "configColumnSet",
                                                    attrs: { dense: "" },
                                                  },
                                                  _vm._l(
                                                    _vm.columnsControl,
                                                    function (column, idx) {
                                                      return _c(
                                                        "q-item",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "ripple",
                                                              rawName:
                                                                "v-ripple",
                                                            },
                                                          ],
                                                          key: idx,
                                                          attrs: {
                                                            tag: "label",
                                                            disable:
                                                              column.disabled,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "q-item-section",
                                                            {
                                                              attrs: {
                                                                side: "",
                                                                top: "",
                                                              },
                                                            },
                                                            [
                                                              column.disabled
                                                                ? _c(
                                                                    "q-checkbox",
                                                                    {
                                                                      staticClass:
                                                                        "tableCheckBox",
                                                                      attrs: {
                                                                        disable: true,
                                                                        color:
                                                                          "orange-custom",
                                                                        value: true,
                                                                      },
                                                                    }
                                                                  )
                                                                : _c(
                                                                    "q-checkbox",
                                                                    {
                                                                      staticClass:
                                                                        "tableCheckBox",
                                                                      attrs: {
                                                                        color:
                                                                          "orange-custom",
                                                                      },
                                                                      nativeOn:
                                                                        {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.colChange(
                                                                                column
                                                                              )
                                                                            },
                                                                        },
                                                                      model: {
                                                                        value:
                                                                          column.check,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              column,
                                                                              "check",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "column.check",
                                                                      },
                                                                    }
                                                                  ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "q-item-section",
                                                            [
                                                              _c(
                                                                "q-item-label",
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$language(
                                                                        column.label
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.collapsed
                                  ? _c(
                                      "q-btn",
                                      {
                                        staticClass: "card-collapse",
                                        attrs: {
                                          flat: "",
                                          dense: "",
                                          color: "grey-6",
                                          icon: _vm.expanded
                                            ? "keyboard_arrow_up"
                                            : "keyboard_arrow_down",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.expanded = !_vm.expanded
                                          },
                                        },
                                      },
                                      [
                                        _c("q-tooltip", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.expanded ? "접기" : "펼치기"
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm._t("customTableTitleEditor"),
                          ]
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                }
              : null,
            _vm.viewHeaders && _vm.viewHeaders.length > 0
              ? {
                  key: "header",
                  fn: function (props) {
                    return _vm._l(_vm.viewHeaders, function (viewHeader, idx) {
                      return _c(
                        "q-tr",
                        { key: idx, ref: "ctr", refInFor: true },
                        [
                          _vm.selection !== "none" && idx === 0 && _vm.editable
                            ? [
                                _c(
                                  "q-th",
                                  {
                                    staticClass: "text-center",
                                    class: [
                                      _vm.fixFlag ? "checkbox-th-fix" : "",
                                      "checkbox-th",
                                    ],
                                    staticStyle: {
                                      "min-width": "24px",
                                      "max-width": "24px",
                                    },
                                    attrs: {
                                      "auto-width": "",
                                      rowspan: _vm.colDepth,
                                    },
                                  },
                                  [
                                    _vm.selection === "multiple"
                                      ? _c("q-checkbox", {
                                          staticClass: "tableCheckBox",
                                          attrs: {
                                            color: "orange-custom",
                                            disable: !_vm.editable,
                                            dense: "",
                                          },
                                          model: {
                                            value: props.selected,
                                            callback: function ($$v) {
                                              _vm.$set(props, "selected", $$v)
                                            },
                                            expression: "props.selected",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                          _vm.isExpand && idx === 0
                            ? _c(
                                "q-th",
                                {
                                  staticClass: "text-center",
                                  attrs: {
                                    "auto-width": "",
                                    rowspan: _vm.colDepth,
                                  },
                                },
                                [_vm._t("expand-header")],
                                2
                              )
                            : _vm._e(),
                          _vm._t("perTh", null, null, props),
                          _vm._l(viewHeader, function (col) {
                            return [
                              (col.colspan === 1 ? idx + 1 === col.level : true)
                                ? _c(
                                    "q-th",
                                    {
                                      key: col.name,
                                      staticClass: "text-center",
                                      style: _vm.setHeaderStyle(col),
                                      attrs: {
                                        "auto-width": false,
                                        colspan: col.colspan,
                                        rowspan:
                                          col.colspan === 1 &&
                                          !col.hasOwnProperty("child")
                                            ? _vm.colDepth - idx
                                            : 1,
                                        props:
                                          _vm.$_.findIndex(_vm.arrangColumns, {
                                            name: col.name,
                                          }) > -1
                                            ? props
                                            : void 0,
                                      },
                                    },
                                    [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$language(col.label)
                                          ),
                                        },
                                      }),
                                      col.required
                                        ? _c(
                                            "font",
                                            { attrs: { color: "red" } },
                                            [_vm._v(" *")]
                                          )
                                        : _vm._e(),
                                      col.headerCustom
                                        ? [
                                            _vm._t("customHeader", null, {
                                              props: props,
                                              col: col,
                                            }),
                                          ]
                                        : _vm._e(),
                                      col.type === "attach" &&
                                      _vm.data &&
                                      _vm.data.length > 0
                                        ? _c(
                                            "span",
                                            [
                                              _c(
                                                "q-btn-group",
                                                { attrs: { outline: "" } },
                                                [
                                                  _c(
                                                    "q-icon",
                                                    {
                                                      staticClass: "custom-btn",
                                                      staticStyle: {
                                                        "font-size": "1.4em",
                                                      },
                                                      attrs: {
                                                        name: "help",
                                                        color: "primary",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "q-tooltip",
                                                        {
                                                          attrs: {
                                                            anchor:
                                                              "bottom left",
                                                            self: "top left",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "tooltipCustomTop",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$language(
                                                                      "업로드 제한사항"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "tooltipCustom",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "q-pl-xs",
                                                                  staticStyle: {
                                                                    "text-align":
                                                                      "left",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "text-positive",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$language(
                                                                            "업로드 가능 확장자"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    " : " +
                                                                      _vm._s(
                                                                        col.hasOwnProperty(
                                                                          "uploaderSetting"
                                                                        )
                                                                          ? col
                                                                              .uploaderSetting
                                                                              .acceptExt
                                                                          : _vm
                                                                              .uploaderSetting
                                                                              .acceptExt
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                  _c("br"),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "text-positive",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$language(
                                                                            "업로드 가능 파일 수"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    " : " +
                                                                      _vm._s(
                                                                        col.hasOwnProperty(
                                                                          "uploaderSetting"
                                                                        )
                                                                          ? col
                                                                              .uploaderSetting
                                                                              .limitCnt
                                                                          : _vm
                                                                              .uploaderSetting
                                                                              .limitCnt
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                  _c("br"),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "text-positive",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$language(
                                                                            "허용 파일 크기"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    " : " +
                                                                      _vm._s(
                                                                        col.hasOwnProperty(
                                                                          "uploaderSetting"
                                                                        )
                                                                          ? col
                                                                              .uploaderSetting
                                                                              .limitSize
                                                                          : _vm
                                                                              .uploaderSetting
                                                                              .limitSize
                                                                      ) +
                                                                      "MB "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      col.helpcomment !== "" &&
                                      col.helpcomment !== null &&
                                      col.helpcomment !== undefined
                                        ? _c(
                                            "span",
                                            [
                                              _c(
                                                "q-btn-group",
                                                { attrs: { outline: "" } },
                                                [
                                                  _c(
                                                    "q-btn",
                                                    {
                                                      staticClass:
                                                        "inner-help-btn",
                                                      attrs: {
                                                        icon: "help",
                                                        color: "deep-purple-6",
                                                        "text-color": "white",
                                                        align: "center",
                                                        round: "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "q-tooltip",
                                                        {
                                                          attrs: {
                                                            anchor:
                                                              "bottom left",
                                                            self: "top left",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "tooltipCustomTop",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$language(
                                                                      "설명"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "tooltipCustom",
                                                            },
                                                            [
                                                              _c("div", {
                                                                staticClass:
                                                                  "q-pl-xs",
                                                                staticStyle: {
                                                                  "text-align":
                                                                    "left",
                                                                },
                                                                domProps: {
                                                                  innerHTML:
                                                                    _vm._s(
                                                                      col.helpcomment
                                                                    ),
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      col.setHeader &&
                                      (col.type || col.headerType) &&
                                      _vm.editable
                                        ? _c(
                                            "div",
                                            [
                                              col.headerType === "checkbox"
                                                ? [
                                                    _c("q-checkbox", {
                                                      staticClass:
                                                        "tableCheckBox",
                                                      attrs: {
                                                        color: "orange-custom",
                                                        disable: !_vm.editable,
                                                        "true-value":
                                                          col.trueValue
                                                            ? col.trueValue
                                                            : "Y",
                                                        "false-value":
                                                          col.falseValue
                                                            ? col.falseValue
                                                            : "N",
                                                        dense: "",
                                                      },
                                                      on: {
                                                        input: (val) =>
                                                          _vm.headerDataChange(
                                                            val,
                                                            props,
                                                            col
                                                          ),
                                                      },
                                                      model: {
                                                        value: col.value,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            col,
                                                            "value",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "col.value",
                                                      },
                                                    }),
                                                  ]
                                                : _vm._e(),
                                              col.type === "select"
                                                ? [
                                                    _c("c-select", {
                                                      attrs: {
                                                        stype:
                                                          "tableHeaderselect",
                                                        editable: _vm.editable,
                                                        codeGroupCd:
                                                          col.codeGroupCd,
                                                        comboItems:
                                                          col.comboItems,
                                                        type: !col.none
                                                          ? "allEdit"
                                                          : "",
                                                        itemText: col.itemText
                                                          ? col.itemText
                                                          : "codeName",
                                                        itemValue: col.itemValue
                                                          ? col.itemValue
                                                          : "code",
                                                      },
                                                      on: {
                                                        datachange: (val) =>
                                                          _vm.headerDataChange(
                                                            val.value,
                                                            props,
                                                            col
                                                          ),
                                                      },
                                                    }),
                                                  ]
                                                : _vm._e(),
                                              col.type === "html2"
                                                ? [
                                                    _c("country-flag", {
                                                      staticClass:
                                                        "lanauageFlag",
                                                      attrs: {
                                                        country: col.lang,
                                                      },
                                                    }),
                                                  ]
                                                : col.type === "check"
                                                ? [
                                                    _c("q-checkbox", {
                                                      staticClass:
                                                        "tableCheckBox",
                                                      attrs: {
                                                        dense: "",
                                                        color: "orange-custom",
                                                        disable: !_vm.editable,
                                                        "true-value": col.true
                                                          ? col.true
                                                          : "O",
                                                        "false-value": col.false
                                                          ? col.false
                                                          : "X",
                                                      },
                                                      on: {
                                                        input: (val) =>
                                                          _vm.headerDataChange(
                                                            val,
                                                            props,
                                                            col
                                                          ),
                                                      },
                                                      model: {
                                                        value: col.value,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            col,
                                                            "value",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "col.value",
                                                      },
                                                    }),
                                                  ]
                                                : col.type === "date"
                                                ? [
                                                    _c("c-datepicker", {
                                                      staticClass:
                                                        "tableDatepicker",
                                                      attrs: {
                                                        dense: "",
                                                        type: col.dateType
                                                          ? col.dateType
                                                          : "date",
                                                        disable: !_vm.editable,
                                                        minuteStep:
                                                          col.minuteStep
                                                            ? col.minuteStep
                                                            : 1,
                                                        start: col.start
                                                          ? col.start
                                                          : "",
                                                        end: col.end
                                                          ? col.end
                                                          : "",
                                                        range:
                                                          col.range === true
                                                            ? true
                                                            : false,
                                                      },
                                                      on: {
                                                        input: (val) =>
                                                          _vm.headerDataChange(
                                                            val,
                                                            props,
                                                            col
                                                          ),
                                                      },
                                                      model: {
                                                        value: col.value,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            col,
                                                            "value",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "col.value",
                                                      },
                                                    }),
                                                  ]
                                                : col.type === "text"
                                                ? [
                                                    col.type === "text"
                                                      ? _c("q-input", {
                                                          staticClass:
                                                            "tableHeaderText",
                                                          attrs: { dense: "" },
                                                          on: {
                                                            input: (val) =>
                                                              _vm.headerDataChange(
                                                                val,
                                                                props,
                                                                col
                                                              ),
                                                          },
                                                          model: {
                                                            value: col.value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                col,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "col.value",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                : col.type === "number"
                                                ? [
                                                    col.type === "number"
                                                      ? _c("q-input", {
                                                          staticClass:
                                                            "tableHeaderText",
                                                          attrs: {
                                                            dense: "",
                                                            type: "number",
                                                          },
                                                          on: {
                                                            input: (val) =>
                                                              _vm.headerDataChange(
                                                                val,
                                                                props,
                                                                col
                                                              ),
                                                          },
                                                          model: {
                                                            value: col.value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                col,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "col.value",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                : col.type === "user"
                                                ? [
                                                    _c("q-btn", {
                                                      staticClass:
                                                        "tableinnerBtn tableinnerUserSearch",
                                                      class: {
                                                        "tableinnerUserSearch-left":
                                                          col.align === "left"
                                                            ? true
                                                            : false,
                                                        "tableinnerUserSearch-right":
                                                          col.align === "right"
                                                            ? true
                                                            : false,
                                                      },
                                                      attrs: {
                                                        flat: "",
                                                        color: "blue-6",
                                                        disable: !_vm.editable,
                                                        icon: "search",
                                                        label: col.value,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.openUserPop(
                                                            {
                                                              userId: "",
                                                              userName: "",
                                                            },
                                                            col,
                                                            "header"
                                                          )
                                                        },
                                                        input: (val) =>
                                                          _vm.headerDataChange(
                                                            val,
                                                            props,
                                                            col
                                                          ),
                                                      },
                                                    }),
                                                  ]
                                                : col.type === "dept"
                                                ? [
                                                    _c("c-dept", {
                                                      attrs: {
                                                        type: "edit",
                                                        label: "",
                                                        isFirstValue: false,
                                                        disable: !_vm.editable,
                                                      },
                                                      on: {
                                                        datachange: (
                                                          deptCd,
                                                          deptName
                                                        ) =>
                                                          _vm.headerDataChange(
                                                            deptCd,
                                                            props,
                                                            col,
                                                            deptName
                                                          ),
                                                      },
                                                      model: {
                                                        value: col.value,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            col,
                                                            "value",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "col.value",
                                                      },
                                                    }),
                                                  ]
                                                : col.type === "custom"
                                                ? [
                                                    col.headType === "select"
                                                      ? _c("c-select", {
                                                          attrs: {
                                                            stype:
                                                              "tableHeaderselect",
                                                            editable:
                                                              _vm.editable,
                                                            comboItems:
                                                              col.comboItems,
                                                            type: !col.none
                                                              ? "allEdit"
                                                              : "",
                                                            itemText:
                                                              col.itemText
                                                                ? col.itemText
                                                                : "codeName",
                                                            itemValue:
                                                              col.itemValue
                                                                ? col.itemValue
                                                                : "code",
                                                          },
                                                          on: {
                                                            datachange: (val) =>
                                                              _vm.headerDataChange(
                                                                val.value,
                                                                props,
                                                                col
                                                              ),
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    col.headType === "link"
                                                      ? _c("q-btn", {
                                                          staticClass:
                                                            "tableinnerBtn",
                                                          class: {
                                                            "tableinnerBtn-left":
                                                              col.align ===
                                                              "left"
                                                                ? true
                                                                : false,
                                                            "tableinnerBtn-right":
                                                              col.align ===
                                                              "right"
                                                                ? true
                                                                : false,
                                                          },
                                                          attrs: {
                                                            flat: "",
                                                            align: col.align,
                                                            size: col.size,
                                                            color: col.color
                                                              ? col.color
                                                              : "blue-6",
                                                            icon: col.icon
                                                              ? col.icon
                                                              : "build",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.headLinkClick(
                                                                props,
                                                                col
                                                              )
                                                            },
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    col.headType === "text"
                                                      ? _c("q-input", {
                                                          staticClass:
                                                            "tableHeaderText",
                                                          attrs: {
                                                            dense: "",
                                                            type: "text",
                                                          },
                                                          on: {
                                                            input: (val) =>
                                                              _vm.headerDataChange(
                                                                val,
                                                                props,
                                                                col
                                                              ),
                                                          },
                                                          model: {
                                                            value: col.value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                col,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "col.value",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    col.headType === "multiUser"
                                                      ? _c("c-multi-field", {
                                                          staticClass:
                                                            "tableHeaderMultiUser",
                                                          attrs: {
                                                            userInfo:
                                                              _vm.userInfo,
                                                            isArray: "",
                                                            type: "user",
                                                            label: "",
                                                          },
                                                          on: {
                                                            "update:userInfo":
                                                              function (
                                                                $event
                                                              ) {
                                                                _vm.userInfo =
                                                                  $event
                                                              },
                                                            "update:user-info":
                                                              function (
                                                                $event
                                                              ) {
                                                                _vm.userInfo =
                                                                  $event
                                                              },
                                                            input: (val) =>
                                                              _vm.headerDataChange(
                                                                val,
                                                                props,
                                                                col
                                                              ),
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ]
                          }),
                          _vm._t("sufTh", null, null, props),
                        ],
                        2
                      )
                    })
                  },
                }
              : null,
            {
              key: "body",
              fn: function (props) {
                return [
                  _vm._t("description-front-tr", null, null, props),
                  !_vm.customDataTr
                    ? [
                        _c(
                          "q-tr",
                          {
                            ref: "compo-tr",
                            class: [
                              {
                                "bg-light-blue-1": _vm.selectedRow(props),
                                "table-tr-highLight": _vm.setHighLight(props),
                                "no-hover-row": _vm.noHoverRow,
                              },
                              _vm.customTrClass(props),
                            ],
                            attrs: { props: props },
                            on: {
                              click: (e) =>
                                _vm.rowClick(e, props.row, props.rowIndex),
                            },
                          },
                          [
                            _vm.selection !== "none" &&
                            _vm.editable &&
                            _vm.isCreate(props.row, -1, props.rowIndex)
                              ? _c(
                                  "q-td",
                                  {
                                    staticClass: "text-center",
                                    class: _vm.fixFlag ? "checkbox-td" : "",
                                    staticStyle: {
                                      "min-width": "24px",
                                      "max-width": "24px",
                                    },
                                    attrs: {
                                      "auto-width": "",
                                      rowspan: _vm.getRowspan(
                                        -1,
                                        props.rowIndex
                                      ),
                                    },
                                  },
                                  [
                                    _c("q-checkbox", {
                                      staticClass: "tableCheckBox",
                                      attrs: {
                                        color: "orange-custom",
                                        disable:
                                          !_vm.editable ||
                                          props.row[_vm.checkDisableColumn],
                                        dense: "",
                                      },
                                      model: {
                                        value: props.selected,
                                        callback: function ($$v) {
                                          _vm.$set(props, "selected", $$v)
                                        },
                                        expression: "props.selected",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isExpand
                              ? _c(
                                  "q-td",
                                  {
                                    staticClass: "text-center",
                                    attrs: { "auto-width": "" },
                                  },
                                  [
                                    _c("q-btn", {
                                      staticClass: "tableInnerIsExpend",
                                      attrs: {
                                        size: "xs",
                                        color: "accent",
                                        round: "",
                                        dense: "",
                                        icon: props.expand ? "remove" : "add",
                                      },
                                      on: {
                                        click: function ($event) {
                                          props.expand = !props.expand
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._t("perTd", null, null, props),
                            _vm._l(props.cols, function (col, colIndex) {
                              return [
                                _vm.isCreate(
                                  props.row,
                                  colIndex,
                                  props.rowIndex
                                )
                                  ? _c(
                                      "q-td",
                                      {
                                        key: col.name,
                                        ref:
                                          "custom-td-" +
                                          props.rowIndex +
                                          "-" +
                                          colIndex,
                                        refInFor: true,
                                        class: [
                                          _vm.checkEnable(col, props.row, props)
                                            ? ""
                                            : `edittd-${col.type}`,
                                          col.ellipsis == true
                                            ? "tableinnerBtn-ellipsis"
                                            : "",
                                        ],
                                        style: _vm.tdStyle(col),
                                        attrs: {
                                          "auto-width": false,
                                          props: props,
                                          rowspan: _vm.getRowspan(
                                            colIndex,
                                            props.rowIndex
                                          ),
                                        },
                                      },
                                      [
                                        _vm.contentsField(props, col)
                                          ? [
                                              _vm.check(col.type)
                                                ? _c(
                                                    "span",
                                                    {
                                                      class: [
                                                        _vm.checkEnable(
                                                          col,
                                                          props.row,
                                                          props
                                                        )
                                                          ? "non-edit"
                                                          : "",
                                                        col.innerBtn
                                                          ? "descript-span"
                                                          : "",
                                                        col.color
                                                          ? "text-" + col.color
                                                          : "",
                                                      ],
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            props.row[col.name]
                                                          ) +
                                                          " "
                                                      ),
                                                      col.description
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "description-td",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    props.row[
                                                                      col
                                                                        .description
                                                                    ]
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      col.innerBtn
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "q-btn-group",
                                                                {
                                                                  staticClass:
                                                                    "ColumInnerBtnGroup",
                                                                  attrs: {
                                                                    outline: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._l(
                                                                    col.btns,
                                                                    function (
                                                                      btn,
                                                                      idx
                                                                    ) {
                                                                      return [
                                                                        _vm.editable &&
                                                                        !_vm.checkEnable(
                                                                          col,
                                                                          props.row,
                                                                          props
                                                                        ) &&
                                                                        !_vm.checkInnerBtnEnable(
                                                                          btn,
                                                                          props.row,
                                                                          props
                                                                        )
                                                                          ? _c(
                                                                              "q-btn",
                                                                              {
                                                                                key: idx,
                                                                                staticClass:
                                                                                  "ColumInnerBtn",
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      btn.label,
                                                                                    icon: btn.icon
                                                                                      ? btn.icon
                                                                                      : void 0,
                                                                                    color:
                                                                                      btn.color
                                                                                        ? btn.color
                                                                                        : "blue-grey-4",
                                                                                    "text-color":
                                                                                      btn.textColor
                                                                                        ? btn.textColor
                                                                                        : "white",
                                                                                    align:
                                                                                      "center",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      $event.stopPropagation()
                                                                                      return _vm.innerBtnClicked(
                                                                                        col,
                                                                                        props,
                                                                                        btn
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                btn.tooltip
                                                                                  ? _c(
                                                                                      "q-tooltip",
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            domProps:
                                                                                              {
                                                                                                innerHTML:
                                                                                                  _vm._s(
                                                                                                    _vm.$language(
                                                                                                      btn.tooltip
                                                                                                    )
                                                                                                  ),
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    }
                                                                  ),
                                                                ],
                                                                2
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.editTdCheck(col.type)
                                                ? [
                                                    col.type === "text"
                                                      ? [
                                                          _c("c-text-column", {
                                                            attrs: {
                                                              editable:
                                                                _vm.editable,
                                                              disabled:
                                                                props.row[
                                                                  _vm
                                                                    .checkDisableColumn
                                                                ] ||
                                                                _vm.checkEnable(
                                                                  col,
                                                                  props.row,
                                                                  props
                                                                ),
                                                              col: col,
                                                              props: props,
                                                              colorClass:
                                                                _vm.checkColorCloass(
                                                                  col,
                                                                  props.row
                                                                ),
                                                            },
                                                            on: {
                                                              datachange:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.datachange(
                                                                    props,
                                                                    col
                                                                  )
                                                                },
                                                            },
                                                          }),
                                                        ]
                                                      : col.type === "textarea"
                                                      ? [
                                                          _c(
                                                            "c-textarea-column",
                                                            {
                                                              attrs: {
                                                                editable:
                                                                  _vm.editable,
                                                                disabled:
                                                                  props.row[
                                                                    _vm
                                                                      .checkDisableColumn
                                                                  ] ||
                                                                  _vm.checkEnable(
                                                                    col,
                                                                    props.row,
                                                                    props
                                                                  ),
                                                                col: col,
                                                                props: props,
                                                              },
                                                              on: {
                                                                datachange:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.datachange(
                                                                      props,
                                                                      col
                                                                    )
                                                                  },
                                                              },
                                                            }
                                                          ),
                                                        ]
                                                      : col.type === "select"
                                                      ? [
                                                          _c("c-select", {
                                                            attrs: {
                                                              stype:
                                                                "tableselect",
                                                              editable:
                                                                _vm.editable,
                                                              disabled:
                                                                !_vm.editable ||
                                                                props.row[
                                                                  _vm
                                                                    .checkDisableColumn
                                                                ] ||
                                                                _vm.checkEnable(
                                                                  col,
                                                                  props.row,
                                                                  props
                                                                ),
                                                              isSelect:
                                                                col.isSelect ===
                                                                  true ||
                                                                col.isSelect ===
                                                                  false
                                                                  ? col.isSelect
                                                                  : true,
                                                              isChip:
                                                                col.isChip,
                                                              comboItems:
                                                                col.comboItems,
                                                              codeGroupCd:
                                                                col.codeGroupCd,
                                                              stepperGrpCd:
                                                                col.stepperGrpCd,
                                                              type: !col.none
                                                                ? "edit"
                                                                : "",
                                                              itemText:
                                                                col.itemText
                                                                  ? col.itemText
                                                                  : "codeName",
                                                              itemValue:
                                                                col.itemValue
                                                                  ? col.itemValue
                                                                  : "code",
                                                            },
                                                            on: {
                                                              datachange: (
                                                                val
                                                              ) =>
                                                                _vm.changeSelect(
                                                                  val,
                                                                  props,
                                                                  col
                                                                ),
                                                            },
                                                            model: {
                                                              value:
                                                                props.row[
                                                                  col.name
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    props.row,
                                                                    col.name,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "props.row[col.name]",
                                                            },
                                                          }),
                                                        ]
                                                      : col.type === "date"
                                                      ? [
                                                          _c("c-datepicker", {
                                                            staticClass:
                                                              "tableDatepicker",
                                                            attrs: {
                                                              dense: "",
                                                              type: col.dateType
                                                                ? col.dateType
                                                                : "date",
                                                              disabled:
                                                                _vm.checkEnable(
                                                                  col,
                                                                  props.row,
                                                                  props
                                                                ) ||
                                                                !_vm.editable ||
                                                                props.row[
                                                                  _vm
                                                                    .checkDisableColumn
                                                                ],
                                                              range:
                                                                col.range ===
                                                                true
                                                                  ? true
                                                                  : false,
                                                              minuteStep:
                                                                col.minuteStep
                                                                  ? col.minuteStep
                                                                  : 1,
                                                              start: col.start
                                                                ? col.start
                                                                : "",
                                                              end: col.end
                                                                ? col.end
                                                                : "",
                                                            },
                                                            on: {
                                                              datachange: (
                                                                val
                                                              ) =>
                                                                _vm.datachange(
                                                                  props,
                                                                  col
                                                                ),
                                                            },
                                                            model: {
                                                              value:
                                                                props.row[
                                                                  col.name
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    props.row,
                                                                    col.name,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "props.row[col.name]",
                                                            },
                                                          }),
                                                        ]
                                                      : col.type === "plant"
                                                      ? [
                                                          _c("c-plant", {
                                                            attrs: {
                                                              isTable: true,
                                                              stype:
                                                                "tableselect",
                                                              editable:
                                                                _vm.editable,
                                                              label: "",
                                                              type: "none",
                                                            },
                                                            on: {
                                                              datachange: (
                                                                val
                                                              ) =>
                                                                _vm.datachange(
                                                                  props,
                                                                  col
                                                                ),
                                                            },
                                                            model: {
                                                              value:
                                                                props.row[
                                                                  col.name
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    props.row,
                                                                    col.name,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "props.row[col.name]",
                                                            },
                                                          }),
                                                        ]
                                                      : col.type === "plantep"
                                                      ? [
                                                          _c("c-plant", {
                                                            attrs: {
                                                              isTable: true,
                                                              stype:
                                                                "tableselect",
                                                              editable:
                                                                _vm.editable,
                                                              label: "",
                                                              type: "none",
                                                              isEnterprise: true,
                                                            },
                                                            on: {
                                                              datachange: (
                                                                val
                                                              ) =>
                                                                _vm.datachange(
                                                                  props,
                                                                  col
                                                                ),
                                                            },
                                                            model: {
                                                              value:
                                                                props.row[
                                                                  col.name
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    props.row,
                                                                    col.name,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "props.row[col.name]",
                                                            },
                                                          }),
                                                        ]
                                                      : col.type === "number"
                                                      ? [
                                                          _c(
                                                            "c-number-column",
                                                            {
                                                              attrs: {
                                                                editable:
                                                                  _vm.editable,
                                                                disabled:
                                                                  props.row[
                                                                    _vm
                                                                      .checkDisableColumn
                                                                  ] ||
                                                                  _vm.checkEnable(
                                                                    col,
                                                                    props.row,
                                                                    props
                                                                  ),
                                                                maxlength:
                                                                  col.maxlength >
                                                                  0
                                                                    ? col.maxlength
                                                                    : void 0,
                                                                col: col,
                                                                props: props,
                                                                colorClass:
                                                                  _vm.checkColorCloass(
                                                                    col,
                                                                    props.row
                                                                  ),
                                                              },
                                                              on: {
                                                                datachange:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.datachange(
                                                                      props,
                                                                      col
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  props.row[
                                                                    col.name
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      props.row,
                                                                      col.name,
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "props.row[col.name]",
                                                              },
                                                            }
                                                          ),
                                                        ]
                                                      : col.type === "check"
                                                      ? [
                                                          _c("q-checkbox", {
                                                            staticClass:
                                                              "tableCheckBox",
                                                            attrs: {
                                                              dense: "",
                                                              color: col.color
                                                                ? col.color
                                                                : "orange-custom",
                                                              disable:
                                                                _vm.checkEnable(
                                                                  col,
                                                                  props.row,
                                                                  props
                                                                ) ||
                                                                !_vm.editable,
                                                              "true-value":
                                                                col.true
                                                                  ? col.true
                                                                  : "O",
                                                              "false-value":
                                                                col.false
                                                                  ? col.false
                                                                  : "X",
                                                              "checked-icon":
                                                                col.checkIcon
                                                                  ? col.checkIcon
                                                                  : void 0,
                                                              "unchecked-icon":
                                                                col.uncheckIcon
                                                                  ? col.uncheckIcon
                                                                  : void 0,
                                                            },
                                                            on: {
                                                              input: (val) =>
                                                                _vm.datachange(
                                                                  props,
                                                                  col
                                                                ),
                                                            },
                                                            model: {
                                                              value:
                                                                props.row[
                                                                  col.name
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    props.row,
                                                                    col.name,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "props.row[col.name]",
                                                            },
                                                          }),
                                                        ]
                                                      : col.type === "radio"
                                                      ? [
                                                          _c("c-radio", {
                                                            attrs: {
                                                              disabled:
                                                                _vm.checkEnable(
                                                                  col,
                                                                  props.row,
                                                                  props
                                                                ) ||
                                                                !_vm.editable,
                                                              comboItems:
                                                                col.comboItems,
                                                              codeGroupCd:
                                                                col.codeGroupCd
                                                                  ? col.codeGroupCd
                                                                  : "",
                                                              itemText:
                                                                col.itemText
                                                                  ? col.itemText
                                                                  : "codeName",
                                                              itemValue:
                                                                col.itemValue
                                                                  ? col.itemValue
                                                                  : "code",
                                                              label: "",
                                                            },
                                                            on: {
                                                              datachange:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.datachange(
                                                                    props,
                                                                    col
                                                                  )
                                                                },
                                                            },
                                                            model: {
                                                              value:
                                                                props.row[
                                                                  col.name
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    props.row,
                                                                    col.name,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "props.row[col.name]",
                                                            },
                                                          }),
                                                        ]
                                                      : col.type === "dept"
                                                      ? [
                                                          _vm.editable
                                                            ? _c("c-dept", {
                                                                attrs: {
                                                                  type: "edit",
                                                                  label: "",
                                                                  isFirstValue:
                                                                    col.isFirstValue,
                                                                  disabled:
                                                                    !_vm.editable ||
                                                                    props.row[
                                                                      _vm
                                                                        .checkDisableColumn
                                                                    ] ||
                                                                    _vm.checkEnable(
                                                                      col,
                                                                      props.row,
                                                                      props
                                                                    ),
                                                                },
                                                                on: {
                                                                  setDeptName: (
                                                                    val
                                                                  ) =>
                                                                    (props.row[
                                                                      col.name
                                                                    ] = val),
                                                                  datachange:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.datachange(
                                                                        props,
                                                                        col
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    props.row[
                                                                      col.deptCd
                                                                    ],
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        props.row,
                                                                        col.deptCd,
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "props.row[col.deptCd]",
                                                                },
                                                              })
                                                            : _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      props.row[
                                                                        col.name
                                                                      ]
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                        ]
                                                      : col.type === "deptMulti"
                                                      ? [
                                                          _vm.editable
                                                            ? _c(
                                                                "c-dept-multi",
                                                                {
                                                                  attrs: {
                                                                    type: "edit",
                                                                    label: "",
                                                                    disabled:
                                                                      !_vm.editable ||
                                                                      props.row[
                                                                        _vm
                                                                          .checkDisableColumn
                                                                      ] ||
                                                                      _vm.checkEnable(
                                                                        col,
                                                                        props.row,
                                                                        props
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    setDeptName:
                                                                      (val) =>
                                                                        (props.row[
                                                                          col.name
                                                                        ] =
                                                                          val),
                                                                    datachange:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.datachange(
                                                                          props,
                                                                          col
                                                                        )
                                                                      },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      props.row[
                                                                        col
                                                                          .deptCd
                                                                      ],
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          props.row,
                                                                          col.deptCd,
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "props.row[col.deptCd]",
                                                                  },
                                                                }
                                                              )
                                                            : _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      props.row[
                                                                        col.name
                                                                      ]
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                        ]
                                                      : col.type === "user"
                                                      ? [
                                                          _vm.checkUserTd(
                                                            col,
                                                            props
                                                          )
                                                            ? _c("q-btn", {
                                                                staticClass:
                                                                  "tableinnerBtn tableinnerUserSearch",
                                                                class: {
                                                                  "tableinnerUserSearch-left":
                                                                    col.align ===
                                                                    "left"
                                                                      ? true
                                                                      : false,
                                                                  "tableinnerUserSearch-right":
                                                                    col.align ===
                                                                    "right"
                                                                      ? true
                                                                      : false,
                                                                },
                                                                attrs: {
                                                                  flat: "",
                                                                  color:
                                                                    "blue-6",
                                                                  disable:
                                                                    _vm.checkEnable(
                                                                      col,
                                                                      props.row,
                                                                      props
                                                                    ) ||
                                                                    !_vm.editable ||
                                                                    props.row[
                                                                      _vm
                                                                        .checkDisableColumn
                                                                    ],
                                                                  icon: _vm.checkEnable(
                                                                    col,
                                                                    props.row,
                                                                    props
                                                                  )
                                                                    ? "search_off"
                                                                    : "search",
                                                                  label:
                                                                    props.row[
                                                                      col.name
                                                                    ],
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      return _vm.openUserPop(
                                                                        props.row,
                                                                        col,
                                                                        "td"
                                                                      )
                                                                    },
                                                                },
                                                              })
                                                            : [
                                                                _c(
                                                                  "c-text-column",
                                                                  {
                                                                    attrs: {
                                                                      editable:
                                                                        _vm.editable,
                                                                      disabled:
                                                                        props
                                                                          .row[
                                                                          _vm
                                                                            .checkDisableColumn
                                                                        ] ||
                                                                        _vm.checkEnable(
                                                                          col,
                                                                          props.row,
                                                                          props
                                                                        ),
                                                                      col: col,
                                                                      props:
                                                                        props,
                                                                    },
                                                                    on: {
                                                                      datachange:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.datachange(
                                                                            props,
                                                                            col
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                        ]
                                                      : col.type === "vendor"
                                                      ? _c("c-vendor", {
                                                          attrs: {
                                                            disabled:
                                                              !_vm.editable ||
                                                              props.row[
                                                                _vm
                                                                  .checkDisableColumn
                                                              ] ||
                                                              _vm.checkEnable(
                                                                col,
                                                                props.row,
                                                                props
                                                              ),
                                                            type: "edit",
                                                            label: "",
                                                          },
                                                          on: {
                                                            dataChange: (
                                                              val,
                                                              oldVal,
                                                              vendor
                                                            ) =>
                                                              _vm.vendorChange(
                                                                props,
                                                                col,
                                                                vendor
                                                              ),
                                                          },
                                                          model: {
                                                            value:
                                                              props.row[
                                                                col.name
                                                              ],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                props.row,
                                                                col.name,
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "props.row[col.name]",
                                                          },
                                                        })
                                                      : col.type === "facility"
                                                      ? _c("c-facility", {
                                                          attrs: {
                                                            disabled:
                                                              !_vm.editable ||
                                                              props.row[
                                                                _vm
                                                                  .checkDisableColumn
                                                              ] ||
                                                              _vm.checkEnable(
                                                                col,
                                                                props.row,
                                                                props
                                                              ),
                                                            detail: false,
                                                            type: "edit",
                                                            label: "",
                                                          },
                                                          on: {
                                                            dataChange: (
                                                              facility
                                                            ) =>
                                                              _vm.facilityChange(
                                                                props,
                                                                col,
                                                                facility
                                                              ),
                                                          },
                                                          model: {
                                                            value:
                                                              props.row[
                                                                col.name
                                                              ],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                props.row,
                                                                col.name,
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "props.row[col.name]",
                                                          },
                                                        })
                                                      : col.type === "equip"
                                                      ? _c("c-equip", {
                                                          attrs: {
                                                            disabled:
                                                              !_vm.editable ||
                                                              props.row[
                                                                _vm
                                                                  .checkDisableColumn
                                                              ] ||
                                                              _vm.checkEnable(
                                                                col,
                                                                props.row,
                                                                props
                                                              ),
                                                            type: "edit",
                                                            label: "",
                                                            name: "equipmentCd",
                                                          },
                                                          on: {
                                                            dataChange: (
                                                              equip
                                                            ) =>
                                                              _vm.equipChange(
                                                                props,
                                                                col,
                                                                equip
                                                              ),
                                                          },
                                                          model: {
                                                            value:
                                                              props.row[
                                                                col.name
                                                              ],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                props.row,
                                                                col.name,
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "props.row[col.name]",
                                                          },
                                                        })
                                                      : col.type === "attach"
                                                      ? [
                                                          _c(
                                                            "c-upload-column",
                                                            {
                                                              staticClass:
                                                                "tableUploadColumn",
                                                              attrs: {
                                                                dense: "",
                                                                editable:
                                                                  !_vm.checkEnable(
                                                                    col,
                                                                    props.row,
                                                                    props
                                                                  ) &&
                                                                  _vm.editable &&
                                                                  !props.row[
                                                                    _vm
                                                                      .checkDisableColumn
                                                                  ],
                                                                imageRestriction:
                                                                  col.imageRestriction,
                                                                col: col,
                                                                row: props.row,
                                                                uploaderSetting:
                                                                  _vm.uploaderSetting,
                                                                colUploaderSetting:
                                                                  col.uploaderSetting,
                                                                change:
                                                                  _vm.change,
                                                              },
                                                              on: {
                                                                "update:row":
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      props,
                                                                      "row",
                                                                      $event
                                                                    )
                                                                  },
                                                                "update:uploaderSetting":
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.uploaderSetting =
                                                                      $event
                                                                  },
                                                                "update:uploader-setting":
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.uploaderSetting =
                                                                      $event
                                                                  },
                                                                "update:colUploaderSetting":
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      col,
                                                                      "uploaderSetting",
                                                                      $event
                                                                    )
                                                                  },
                                                                "update:col-uploader-setting":
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      col,
                                                                      "uploaderSetting",
                                                                      $event
                                                                    )
                                                                  },
                                                                "update:change":
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.change =
                                                                      $event
                                                                  },
                                                                "upload-change":
                                                                  (data) => {
                                                                    _vm.uploadChange(
                                                                      data,
                                                                      props,
                                                                      col
                                                                    )
                                                                  },
                                                              },
                                                            }
                                                          ),
                                                        ]
                                                      : col.type === "file"
                                                      ? [
                                                          _c("c-file-column", {
                                                            attrs: {
                                                              editable:
                                                                !_vm.checkEnable(
                                                                  col,
                                                                  props.row,
                                                                  props
                                                                ) &&
                                                                _vm.editable &&
                                                                !props.row[
                                                                  _vm
                                                                    .checkDisableColumn
                                                                ],
                                                              col: col,
                                                              row: props.row,
                                                              preview:
                                                                _vm.preview,
                                                            },
                                                            on: {
                                                              "update:row":
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.$set(
                                                                    props,
                                                                    "row",
                                                                    $event
                                                                  )
                                                                },
                                                              "update:preview":
                                                                function (
                                                                  $event
                                                                ) {
                                                                  _vm.preview =
                                                                    $event
                                                                },
                                                            },
                                                          }),
                                                        ]
                                                      : col.type === "datetime"
                                                      ? [
                                                          _c("c-datepicker", {
                                                            staticClass:
                                                              "tableDatepicker",
                                                            attrs: {
                                                              dense: "",
                                                              type: col.datetimeType
                                                                ? col.datetimeType
                                                                : "time",
                                                              timePickerOptions:
                                                                _vm.setTimePickerOptions(
                                                                  props,
                                                                  col
                                                                ),
                                                              range:
                                                                col.range ===
                                                                true
                                                                  ? true
                                                                  : false,
                                                              disabled:
                                                                _vm.checkEnable(
                                                                  col,
                                                                  props.row,
                                                                  props
                                                                ) ||
                                                                !_vm.editable ||
                                                                props.row[
                                                                  _vm
                                                                    .checkDisableColumn
                                                                ],
                                                              minuteStep:
                                                                col.minuteStep
                                                                  ? col.minuteStep
                                                                  : 1,
                                                            },
                                                            on: {
                                                              datachange: (
                                                                val
                                                              ) =>
                                                                _vm.datachange(
                                                                  props,
                                                                  col
                                                                ),
                                                            },
                                                            model: {
                                                              value:
                                                                props.row[
                                                                  col.name
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    props.row,
                                                                    col.name,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "props.row[col.name]",
                                                            },
                                                          }),
                                                        ]
                                                      : col.type ===
                                                        "multiSelect"
                                                      ? _c("c-multi-select", {
                                                          attrs: {
                                                            stype:
                                                              "tableselect",
                                                            editable:
                                                              _vm.editable,
                                                            disabled:
                                                              _vm.checkEnable(
                                                                col,
                                                                props.row,
                                                                props
                                                              ),
                                                            isArray:
                                                              col.isArray ===
                                                              false
                                                                ? false
                                                                : true,
                                                            isObject:
                                                              col.isObject,
                                                            codeGroupCd:
                                                              col.codeGroupCd
                                                                ? col.codeGroupCd
                                                                : "",
                                                            stepperGrpCd:
                                                              col.stepperGrpCd
                                                                ? col.stepperGrpCd
                                                                : "",
                                                            valueText:
                                                              col.valueText
                                                                ? col.valueText
                                                                : "codeName",
                                                            valueKey:
                                                              col.valueKey
                                                                ? col.valueKey
                                                                : "code",
                                                            comboItems:
                                                              col.comboItems,
                                                            itemText:
                                                              col.itemText
                                                                ? col.itemText
                                                                : "codeName",
                                                            itemValue:
                                                              col.itemValue
                                                                ? col.itemValue
                                                                : "code",
                                                          },
                                                          on: {
                                                            datachange: (val) =>
                                                              _vm.datachange(
                                                                props,
                                                                col
                                                              ),
                                                          },
                                                          model: {
                                                            value:
                                                              props.row[
                                                                col.name
                                                              ],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                props.row,
                                                                col.name,
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "props.row[col.name]",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                : [
                                                    col.type === "link"
                                                      ? [
                                                          props.row[col.name]
                                                            ? _c("q-btn", {
                                                                staticClass:
                                                                  "tableinnerBtn",
                                                                class: {
                                                                  "tableinnerBtn-left":
                                                                    col.align ===
                                                                    "left"
                                                                      ? true
                                                                      : false,
                                                                  "tableinnerBtn-right":
                                                                    col.align ===
                                                                    "right"
                                                                      ? true
                                                                      : false,
                                                                  "tableinnerBtn-ellipsis":
                                                                    col.ellipsis ==
                                                                    true
                                                                      ? true
                                                                      : false,
                                                                },
                                                                attrs: {
                                                                  flat: "",
                                                                  align:
                                                                    col.align,
                                                                  color:
                                                                    "blue-6",
                                                                  label:
                                                                    props.row[
                                                                      col.name
                                                                    ],
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      return _vm.linkClick(
                                                                        props,
                                                                        col
                                                                      )
                                                                    },
                                                                },
                                                              })
                                                            : _vm._e(),
                                                        ]
                                                      : col.type === "tag"
                                                      ? [
                                                          props.row[col.name]
                                                            ? _c(
                                                                "q-chip",
                                                                {
                                                                  attrs: {
                                                                    outline: "",
                                                                    square: "",
                                                                    color:
                                                                      _vm.setTagColor(
                                                                        col,
                                                                        props
                                                                          .row[
                                                                          col
                                                                            .name
                                                                        ]
                                                                      ),
                                                                    "text-color":
                                                                      col.colorItems
                                                                        ? "white"
                                                                        : "black",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.setTagName(
                                                                          col,
                                                                          props
                                                                            .row[
                                                                            col
                                                                              .name
                                                                          ]
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : void 0,
                                                        ]
                                                      : col.type === "custom"
                                                      ? [
                                                          _vm._t(
                                                            "customArea",
                                                            null,
                                                            {
                                                              props: props,
                                                              col: col,
                                                            }
                                                          ),
                                                        ]
                                                      : col.type === "cost"
                                                      ? _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                col.prefix
                                                                  ? col.prefix
                                                                  : ""
                                                              ) +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "toThousandFilter"
                                                                )(
                                                                  props.row[
                                                                    col.name
                                                                  ]
                                                                )
                                                              ) +
                                                              _vm._s(
                                                                col.suffix
                                                                  ? col.suffix
                                                                  : ""
                                                              ) +
                                                              " "
                                                          ),
                                                        ])
                                                      : col.type === "html"
                                                      ? _c("span", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              _vm.$comm.convertEnter(
                                                                props.row[
                                                                  col.name
                                                                ]
                                                              )
                                                            ),
                                                          },
                                                        })
                                                      : col.type === "html2"
                                                      ? _c("span", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              _vm.$comm.convertEnter(
                                                                props.row[
                                                                  col.name
                                                                ]
                                                              )
                                                            ),
                                                          },
                                                        })
                                                      : col.type === "color"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            class:
                                                              props.row[
                                                                col.name
                                                              ] === "Y"
                                                                ? "tdBackGroundColor"
                                                                : "tdBackGroundColorNone",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  props.row[
                                                                    col.name
                                                                  ]
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : col.type === "badge"
                                                      ? _c(
                                                          "span",
                                                          [
                                                            _c("q-badge", {
                                                              attrs: {
                                                                color:
                                                                  col.color,
                                                                label:
                                                                  props.row[
                                                                    col.name
                                                                  ],
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      : col.type === "img"
                                                      ? _c("span", [
                                                          _c("img", {
                                                            class: {
                                                              "cursor-pointer":
                                                                Boolean(
                                                                  props.row
                                                                    .sysAttachFileId
                                                                ),
                                                            },
                                                            staticStyle: {
                                                              width: "150px",
                                                            },
                                                            attrs: {
                                                              src: props.row[
                                                                col.name
                                                              ],
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.setPreview(
                                                                  props.row
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ])
                                                      : col.type === "proxy"
                                                      ? _c(
                                                          "q-btn",
                                                          {
                                                            staticClass:
                                                              "tableinnerBtn",
                                                            attrs: {
                                                              flat: "",
                                                              align: col.align,
                                                              color: "blue-6",
                                                              label: props.row[
                                                                col.name
                                                              ]
                                                                ? props.row[
                                                                    col.name
                                                                  ]
                                                                : "보기",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "q-popup-proxy",
                                                              {
                                                                ref:
                                                                  "proxy_" +
                                                                  props.rowIndex +
                                                                  "_" +
                                                                  col.name,
                                                                refInFor: true,
                                                                attrs: {
                                                                  breakpoint: 1000,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  col.component,
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      props:
                                                                        props,
                                                                      col: col,
                                                                      editable:
                                                                        _vm.editable,
                                                                    },
                                                                    on: {
                                                                      callback:
                                                                        (
                                                                          data,
                                                                          color
                                                                        ) =>
                                                                          _vm.callbackProxy(
                                                                            data,
                                                                            color,
                                                                            props,
                                                                            col
                                                                          ),
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ]
                            }),
                            _vm._t("sufTd", null, null, props),
                          ],
                          2
                        ),
                      ]
                    : [_vm._t("customDataTr", null, null, props)],
                  _vm.showDescription
                    ? _c(
                        "q-tr",
                        {
                          key: `e_${props.rowIndex}`,
                          staticClass:
                            "q-virtual-scroll--with-prev description-tr",
                          attrs: { props: props },
                        },
                        [
                          _c(
                            "q-td",
                            {
                              staticClass: "description-td",
                              attrs: { colspan: "100%" },
                            },
                            [_vm._t("description-td", null, null, props)],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  props.expand
                    ? _c(
                        "q-tr",
                        { attrs: { props: props } },
                        [
                          _c(
                            "q-td",
                            { attrs: { colspan: "100%" } },
                            [_vm._t("expand-td", null, null, props)],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
            },
            {
              key: "bottom-row",
              fn: function () {
                return [_vm._t("customBottomRow")]
              },
              proxy: true,
            },
            {
              key: "bottom",
              fn: function () {
                return [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$language("총 건수")) +
                      " : " +
                      _vm._s(
                        _vm._f("toThousandFilter")(
                          _vm.data ? _vm.data.length : 0
                        )
                      ) +
                      " "
                  ),
                  _c("q-space"),
                  _vm.usePaging
                    ? [
                        _c("q-select", {
                          staticClass: "tablePagingInput",
                          staticStyle: {
                            "min-width": "50px",
                            "max-width": "70px",
                          },
                          attrs: {
                            outlined: "",
                            dense: "",
                            options: _vm.pageItems,
                            color: "table-paging-color",
                          },
                          on: { input: _vm.changePageNumber },
                          model: {
                            value: _vm.pagePerRow.pageNumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.pagePerRow, "pageNumber", $$v)
                            },
                            expression: "pagePerRow.pageNumber",
                          },
                        }),
                        _c("q-pagination", {
                          staticClass: "tablePagingBtn",
                          attrs: {
                            max: _vm.pagesNumber,
                            "max-pages": 5,
                            "direction-links": "",
                            "boundary-links": "",
                            "icon-first": "skip_previous",
                            "icon-last": "skip_next",
                            color: "table-paging-color",
                          },
                          model: {
                            value: _vm.initialPagination.page,
                            callback: function ($$v) {
                              _vm.$set(_vm.initialPagination, "page", $$v)
                            },
                            expression: "initialPagination.page",
                          },
                        }),
                      ]
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _vm.preview && _vm.preview.src
        ? _c(
            "q-dialog",
            {
              model: {
                value: _vm.preview.isShow,
                callback: function ($$v) {
                  _vm.$set(_vm.preview, "isShow", $$v)
                },
                expression: "preview.isShow",
              },
            },
            [
              _c(
                "q-card",
                { staticClass: "preview-image-card" },
                [
                  _c(
                    "q-btn",
                    {
                      staticClass: "preview-close-btn",
                      attrs: {
                        color: "white",
                        "text-color": "primary",
                        round: "",
                        dense: "",
                        icon: "close",
                      },
                      on: {
                        click: function ($event) {
                          _vm.preview.isShow = false
                        },
                      },
                    },
                    [
                      _c(
                        "q-tooltip",
                        { staticClass: "bg-white text-primary" },
                        [_vm._v("창닫기")]
                      ),
                    ],
                    1
                  ),
                  _c("q-img", { attrs: { src: _vm.preview.src } }),
                  _c(
                    "q-card-section",
                    [
                      _c("q-btn", {
                        staticClass: "absolute",
                        staticStyle: {
                          top: "0",
                          right: "12px",
                          transform: "translateY(-50%)",
                        },
                        attrs: { fab: "", color: "primary", icon: "save_alt" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.fileDown(_vm.preview)
                          },
                        },
                      }),
                      _c("div", { staticClass: "row no-wrap items-center" }, [
                        _c("div", { staticClass: "col text-h6" }, [
                          _vm._v(" " + _vm._s(_vm.preview.oriFileNm) + " "),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-auto text-grey text-caption q-pt-md row no-wrap items-center",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$comm.bytesToSize(_vm.preview.fileSize)
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }