<!--
  TODO : 버튼 공통 모듈
-->
<template>
  <q-tooltip :content-class="contentClass" :content-style="contentStyle">
    <!-- {{contents}} -->
    <q-list>
      <q-item>
        <q-item-section>
          <q-item-label style="font-weight: 600">{{title}}</q-item-label>
          <q-item-label caption lines="2" class="text-white opacity-6">{{contents['ibm_title'].text}}</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-tooltip>
</template>

<script>
export default {
  /* attributes: name, components, props, data */
  name: 'c-assess-tooltip',
  props: {
    name: {
      type: String,
      default: '',
    },
    ibmTooltip: {
      type: String,
      default: '',
    },
    ibmClassCd: {
      type: String,
      default: '',
    },
    contentClass: {
      type: String,
      default: 'bg-dark text-white',
    },
    contentStyle: {
      type: String,
      default: 'margin-top:-10px !important',
    },
    isVendor: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    contentsItems() {
      return [
        '제목',
        '조치부서',
        '조치완료 요청일',
        '조치일',
      ]
    },
    contentsVendorItems() {
      return [
        '제목',
        '조치일',
      ]
    },
    contents() {
      let _contents = {};
      if (this.ibmTooltip) {
        let idx = 0;
        this.$_.forEach(this.$_.split(this.ibmTooltip, '|'), splitItem => {
          let _item = this.$_.split(splitItem, ':')
          this.$set(_contents, _item[0], {
            text: _item[1],
            label: !this.isVendor ? this.contentsItems[idx] : this.contentsVendorItems[idx]
          })
          idx++;
        })
      }
      return _contents;
    },
    title() {
      let text = '';
      if (this.contents) {
        if (!this.isVendor) {
          if (!this.ibmClassCd || this.ibmClassCd === 'IC00000001') {
            // [개선]
            text += `${this.$comm.getLangLabel('LBL0000719')} ${this.contents['action_dept_name'] ? this.contents['action_dept_name'].text : ''} / ${this.contents['action_complete_request_date'] ? this.contents['action_complete_request_date'].text : ''}`
          } else if (this.ibmClassCd === 'IC00000005') {
            // [즉시]
            text += `${this.$comm.getLangLabel('LBL0000720')} ${this.contents['action_dept_name'] ? this.contents['action_dept_name'].text : ''} / ${this.contents['action_complete_date'] ? this.contents['action_complete_date'].text : ''}`
          }
        } else {
          text += `[Punch-list] ${this.contents['action_complete_date'] ? this.contents['action_complete_date'].text : ''}`
        }
      }
      return text;
    }
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  mounted() {
  },
  /* methods */
  methods: {
  },
};
</script>

