var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isUploaderSetting
    ? _c(
        "div",
        { staticClass: "fix-height row upload-cover" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.editable,
                  expression: "editable",
                },
              ],
              staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4",
            },
            [
              _c("q-uploader", {
                ref: _vm.uploadRef,
                staticClass: "custom-upload",
                style: _vm.maxheight,
                attrs: {
                  flat: "",
                  bordered: "",
                  "with-credentials": "",
                  color: "custom-upload-color",
                  "field-name": "file",
                  url: _vm.url,
                  headers: _vm.headers,
                  method: "POST",
                  label: _vm.convertLabel,
                  "form-fields": _vm.formFields,
                  "auto-upload": false,
                  "hide-upload-btn": true,
                  multiple: _vm.uploaderSetting.multipleSelFlag === "Y",
                  accept: _vm.uploaderSetting.acceptExt,
                  "max-file-size": _vm.uploaderSetting.limitSize * 1000000,
                  "max-total-size":
                    _vm.uploaderSetting.limitSize *
                    1000000 *
                    _vm.uploaderSetting.limitCnt,
                  "max-files": _vm.maxFiles,
                  "no-thumbnails": _vm.uploaderSetting.previewFlag === "Y",
                  disable: !_vm.editable,
                },
                on: {
                  added: _vm.added,
                  finish: _vm.finish,
                  failed: _vm.failed,
                  rejected: _vm.rejected,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "row no-wrap items-center q-pa-none q-gutter-xs",
                            },
                            [
                              scope.isUploading
                                ? _c("q-spinner", {
                                    staticClass: "q-uploader__spinner",
                                  })
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass: "col",
                                  staticStyle: {
                                    "padding-top": "0px !important",
                                    "margin-top": "0px !important",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "q-uploader__title inline-block",
                                      staticStyle: {
                                        padding: "4px 0px !important",
                                        "font-size": "0.95em !important",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.convertLabel))]
                                  ),
                                  _vm._v("   "),
                                  _vm.editable
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "q-uploader__subtitle inline-block",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(scope.uploadSizeLabel) +
                                              " / " +
                                              _vm._s(scope.uploadProgressLabel)
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _vm.haveImage()
                                ? _c(
                                    "q-btn",
                                    {
                                      attrs: {
                                        icon: "visibility",
                                        dense: "",
                                        flat: "",
                                        size: "sm",
                                      },
                                      on: { click: _vm.allImagePreView },
                                    },
                                    [
                                      _c("q-tooltip", [
                                        _vm._v(
                                          _vm._s(_vm.$label("LBL0000580"))
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.fileList && _vm.fileList.length > 0
                                ? _c(
                                    "q-btn",
                                    {
                                      attrs: {
                                        icon: "delete_outline",
                                        dense: "",
                                        flat: "",
                                        size: "sm",
                                      },
                                      on: { click: _vm.removeAllFiles },
                                    },
                                    [
                                      _c("q-tooltip", [
                                        _vm._v(
                                          _vm._s(_vm.$label("LBL0000581"))
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "list",
                      fn: function (scope) {
                        return [
                          scope.files && scope.files.length > 0
                            ? [
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  _vm._l(scope.files, function (file) {
                                    return _c(
                                      "q-item",
                                      { key: file.name },
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              {
                                                staticClass:
                                                  "full-width ellipsis",
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(file.name) + " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  " Status: " +
                                                    _vm._s(file.__status) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(file.__sizeLabel) +
                                                    " / " +
                                                    _vm._s(
                                                      file.__progressLabel
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        file.__img
                                          ? _c(
                                              "q-item-section",
                                              {
                                                staticClass: "gt-xs",
                                                attrs: { thumbnail: "" },
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: file.__img.src,
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "q-item-section",
                                          { attrs: { top: "", side: "" } },
                                          [
                                            _c("q-btn", {
                                              staticClass: "gt-xs",
                                              attrs: {
                                                size: "12px",
                                                flat: "",
                                                dense: "",
                                                round: "",
                                                icon: "delete",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return scope.removeFile(file)
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                ),
                              ]
                            : [
                                _c(
                                  "div",
                                  { staticClass: "empty_dscription" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.editable,
                                            expression: "editable",
                                          },
                                        ],
                                        staticStyle: {
                                          "text-align": "center",
                                          "padding-bottom": "5px",
                                        },
                                      },
                                      [
                                        _c(
                                          "q-chip",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              outline: "",
                                              square: "",
                                              icon: "info",
                                            },
                                          },
                                          [_vm._v(" Drag or Click ")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "q-pl-md txtfileinfo",
                                        staticStyle: { "text-align": "left" },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-positive" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$label("LBL0000526"))
                                            ),
                                          ]
                                        ),
                                        _vm._v(
                                          " : " +
                                            _vm._s(
                                              _vm.uploaderSetting.acceptExt
                                            ) +
                                            " "
                                        ),
                                        _c("br"),
                                        _c(
                                          "span",
                                          { staticClass: "text-positive" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$label("LBL0000527"))
                                            ),
                                          ]
                                        ),
                                        _vm._v(
                                          " : " +
                                            _vm._s(
                                              _vm.uploaderSetting.limitCnt
                                            ) +
                                            " "
                                        ),
                                        _c("br"),
                                        _c(
                                          "span",
                                          { staticClass: "text-positive" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$label("LBL0000528"))
                                            ),
                                          ]
                                        ),
                                        _vm._v(
                                          " : " +
                                            _vm._s(
                                              _vm.uploaderSetting.limitSize
                                            ) +
                                            "MB "
                                        ),
                                      ]
                                    ),
                                    _c("q-uploader-add-trigger"),
                                  ],
                                  1
                                ),
                              ],
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4134560143
                ),
              }),
            ],
            1
          ),
          _c(
            "div",
            { class: _vm.fileListClass },
            [
              !_vm.isPhoto
                ? _c(
                    "q-list",
                    {
                      staticClass: "uploaded-file-list",
                      attrs: { separator: "", bordered: "" },
                    },
                    [
                      _vm.fileList && _vm.fileList.length > 0
                        ? [
                            _c(
                              "q-item",
                              { staticClass: "fileuploadItem" },
                              [
                                _c(
                                  "font",
                                  {
                                    staticStyle: {
                                      "font-size": "12px",
                                      "font-weight": "600",
                                    },
                                  },
                                  [_vm._v("[" + _vm._s(_vm.convertLabel) + "]")]
                                ),
                              ],
                              1
                            ),
                            _vm._l(_vm.fileList, function (file, idx) {
                              return _c(
                                "q-item",
                                { key: idx, staticClass: "fileuploadItem" },
                                [
                                  _c(
                                    "q-item-section",
                                    { staticClass: "fileuploadColumn" },
                                    [
                                      _c(
                                        "q-item-label",
                                        {
                                          staticClass:
                                            "full-width file-origin-name",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-blue cursor-pointer",
                                              staticStyle: {
                                                "font-size":
                                                  "0.95em !important",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.fileDown(file)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(file.oriFileNm) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "text-caption" },
                                            [
                                              _vm._v(
                                                " (" +
                                                  _vm._s(
                                                    _vm.getFileSizeTextByRound(
                                                      file.fileSize
                                                    )
                                                  ) +
                                                  ") "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm.uploaderSetting.explainFlag === "Y"
                                        ? _c(
                                            "q-item-label",
                                            [
                                              _c("c-text", {
                                                staticClass: "file-description",
                                                attrs: {
                                                  editable: _vm.editable,
                                                  name: "description",
                                                  placeholder:
                                                    _vm.$comm.getLangLabel(
                                                      "LBL0000582"
                                                    ),
                                                },
                                                on: {
                                                  blur: () =>
                                                    _vm.descriptionSave(file),
                                                },
                                                model: {
                                                  value: file.description,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      file,
                                                      "description",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "file.description",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  file.contentType &&
                                  file.contentType.indexOf("image") > -1
                                    ? _c(
                                        "q-item-section",
                                        {
                                          staticClass: "gt-xs cursor-pointer",
                                          attrs: { thumbnail: "" },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "thumnailUpload",
                                            attrs: {
                                              src: file.imgUrl
                                                ? file.imgUrl
                                                : file.filePath,
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.setPreview(
                                                  file,
                                                  "single"
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "q-item-section",
                                    { attrs: { side: "" } },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _vm.editable
                                            ? _c(
                                                "q-btn",
                                                {
                                                  staticClass: "gt-xs",
                                                  attrs: {
                                                    size: "11px",
                                                    flat: "",
                                                    dense: "",
                                                    round: "",
                                                    icon: "delete",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.removeFile(
                                                        file
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("q-tooltip", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$label("LBL0000525")
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                          ]
                        : [
                            _c(
                              "q-item",
                              {
                                staticClass: "fileuploadItem nofileArea",
                                style: { display: "block" },
                              },
                              [
                                [
                                  _c(
                                    "font",
                                    {
                                      staticStyle: {
                                        "font-size": "12px",
                                        "font-weight": "600",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "[" + _vm._s(_vm.convertLabel) + "]"
                                      ),
                                    ]
                                  ),
                                ],
                                _c("div", [
                                  _vm._v(
                                    " " + _vm._s(_vm.$label("LBL0000583")) + " "
                                  ),
                                ]),
                              ],
                              2
                            ),
                          ],
                    ],
                    2
                  )
                : _c(
                    "q-list",
                    {
                      staticClass: "uploaded-file-list row",
                      attrs: { separator: "", bordered: "" },
                    },
                    [
                      _vm.fileList && _vm.fileList.length > 0
                        ? [
                            _c(
                              "div",
                              { staticClass: "upload-title-font col-12" },
                              [_vm._v(" [" + _vm._s(_vm.convertLabel) + "] ")]
                            ),
                            _vm._l(_vm.fileList, function (file, idx) {
                              return _c(
                                "q-item",
                                { key: idx, staticClass: "col-3 text-center" },
                                [
                                  _c(
                                    "q-item-section",
                                    [
                                      _c(
                                        "q-item-label",
                                        { staticClass: "upload-photo-layer" },
                                        [
                                          _c("img", {
                                            staticClass: "thumnailUploadPhoto",
                                            attrs: {
                                              src: file.imgUrl
                                                ? file.imgUrl
                                                : file.filePath,
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.setPreview(
                                                  file,
                                                  "single"
                                                )
                                              },
                                            },
                                          }),
                                          _vm.editable
                                            ? _c(
                                                "q-btn",
                                                {
                                                  staticClass: "gt-xs",
                                                  attrs: {
                                                    size: "11px",
                                                    flat: "",
                                                    dense: "",
                                                    round: "",
                                                    icon: "delete",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.removeFile(
                                                        file
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("q-tooltip", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$label("LBL0000525")
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                          ]
                        : [
                            _c(
                              "q-item",
                              {
                                staticClass: "fileuploadItem nofileArea",
                                style: { display: "block" },
                              },
                              [
                                [
                                  _c(
                                    "font",
                                    {
                                      staticStyle: {
                                        "font-size": "12px",
                                        "font-weight": "600",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "[" + _vm._s(_vm.convertLabel) + "]"
                                      ),
                                    ]
                                  ),
                                ],
                                _c("div", [
                                  _vm._v(
                                    " " + _vm._s(_vm.$label("LBL0000583")) + " "
                                  ),
                                ]),
                              ],
                              2
                            ),
                          ],
                    ],
                    2
                  ),
            ],
            1
          ),
          _vm.attachInfo.isRev
            ? _c(
                "div",
                { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                [
                  _c(
                    "q-toolbar",
                    {
                      staticClass:
                        "bg-primary text-white before-file-list-toolbar",
                    },
                    [
                      _c("q-toolbar-title", [
                        _vm._v(_vm._s(_vm.$label("LBL0000584"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-list",
                    {
                      staticClass: "uploaded-file-list",
                      attrs: { separator: "", bordered: "" },
                    },
                    [
                      _vm.beforeFileList && _vm.beforeFileList.length > 0
                        ? _vm._l(_vm.beforeFileList, function (file, idx) {
                            return _c(
                              "q-item",
                              { key: idx, staticClass: "fileuploadItem" },
                              [
                                _c(
                                  "q-item-section",
                                  { staticClass: "fileuploadColumn" },
                                  [
                                    _c(
                                      "q-item-label",
                                      {
                                        staticClass:
                                          "full-width file-origin-name",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-blue cursor-pointer",
                                            staticStyle: {
                                              "font-size": "0.95em !important",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.fileDown(file)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(file.oriFileNm) + " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "text-caption" },
                                          [
                                            _vm._v(
                                              " (" +
                                                _vm._s(
                                                  _vm.getFileSizeTextByRound(
                                                    file.fileSize
                                                  )
                                                ) +
                                                ") "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm.uploaderSetting.explainFlag === "Y"
                                      ? _c(
                                          "q-item-label",
                                          [
                                            _c("c-text", {
                                              staticClass: "file-description",
                                              attrs: {
                                                editable: false,
                                                name: "description",
                                              },
                                              model: {
                                                value: file.description,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    file,
                                                    "description",
                                                    $$v
                                                  )
                                                },
                                                expression: "file.description",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                file.contentType &&
                                file.contentType.indexOf("image") > -1
                                  ? _c(
                                      "q-item-section",
                                      {
                                        staticClass: "gt-xs cursor-pointer",
                                        attrs: { thumbnail: "" },
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "thumnailUpload",
                                          attrs: {
                                            src: file.imgUrl
                                              ? file.imgUrl
                                              : file.filePath,
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.setPreview(
                                                file,
                                                "single"
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _c("q-item-section", { attrs: { side: "" } }, [
                                  _c(
                                    "div",
                                    [
                                      _vm.editable
                                        ? _c("q-btn", {
                                            staticClass: "gt-xs",
                                            attrs: {
                                              size: "11px",
                                              flat: "",
                                              dense: "",
                                              round: "",
                                              label:
                                                _vm.$comm.getLangLabel(
                                                  "LBL0000585"
                                                ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.copyFile(file)
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            )
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isPhotoView &&
          _vm.previewfix &&
          (_vm.previewfix.src ||
            (_vm.previewfix.fileList && _vm.previewfix.fileList.length > 0))
            ? _c(
                "div",
                { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" },
                [
                  _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(_vm.previewfix.fileList, function (file, idx) {
                      return _c(
                        "div",
                        {
                          key: idx,
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 fixPreview",
                        },
                        [
                          _c("div", { staticClass: "fixPreviewB" }, [
                            _vm._v(" " + _vm._s(file.description) + " "),
                          ]),
                          _c(
                            "div",
                            { staticClass: "fixPreviewH" },
                            [
                              _c("q-img", {
                                staticClass: "fixPreviewImg",
                                attrs: { src: file.src },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
          _vm.preview &&
          (_vm.preview.src ||
            (_vm.preview.fileList && _vm.preview.fileList.length > 0))
            ? _c(
                "q-dialog",
                {
                  model: {
                    value: _vm.preview.isShow,
                    callback: function ($$v) {
                      _vm.$set(_vm.preview, "isShow", $$v)
                    },
                    expression: "preview.isShow",
                  },
                },
                [
                  _c(
                    "q-card",
                    { staticClass: "preview-image-card" },
                    [
                      _c(
                        "q-btn",
                        {
                          staticClass: "preview-close-btn",
                          attrs: {
                            color: "white",
                            "text-color": "primary",
                            round: "",
                            dense: "",
                            icon: "close",
                          },
                          on: {
                            click: function ($event) {
                              _vm.preview.isShow = false
                            },
                          },
                        },
                        [
                          _c(
                            "q-tooltip",
                            { staticClass: "bg-white text-primary" },
                            [_vm._v("창닫기")]
                          ),
                        ],
                        1
                      ),
                      _vm.preview.fileList && _vm.preview.fileList.length > 0
                        ? _c(
                            "q-carousel",
                            {
                              attrs: {
                                swipeable: "",
                                animated: "",
                                thumbnails: "",
                                infinite: "",
                              },
                              on: { transition: _vm.transitionImage },
                              model: {
                                value: _vm.preview.slide,
                                callback: function ($$v) {
                                  _vm.$set(_vm.preview, "slide", $$v)
                                },
                                expression: "preview.slide",
                              },
                            },
                            _vm._l(_vm.preview.fileList, function (file, idx) {
                              return _c("q-carousel-slide", {
                                key: idx,
                                attrs: {
                                  name: file.sysAttachFileId,
                                  "img-src": file.src,
                                },
                              })
                            }),
                            1
                          )
                        : _c("q-img", { attrs: { src: _vm.preview.src } }),
                      _c(
                        "q-card-section",
                        [
                          _c("q-btn", {
                            staticClass: "absolute",
                            staticStyle: {
                              top: "0",
                              right: "12px",
                              transform: "translateY(-50%)",
                            },
                            attrs: {
                              fab: "",
                              color: "primary",
                              icon: "save_alt",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.fileDown(_vm.preview)
                              },
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "row no-wrap items-center" },
                            [
                              _c("div", { staticClass: "col text-h6" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.preview.oriFileNm) + " "
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-auto text-grey text-caption q-pt-md row no-wrap items-center",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getFileSizeTextByRound(
                                          _vm.preview.fileSize
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.preview.description ? _c("q-separator") : _vm._e(),
                      _vm.preview.description
                        ? _c("q-card-section", { staticClass: "q-pt-none" }, [
                            _c(
                              "div",
                              { staticClass: "text-subtitle1" },
                              [
                                _c("q-icon", { attrs: { name: "info" } }),
                                _vm._v(
                                  " " + _vm._s(_vm.$label("LBL0000586")) + " "
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "text-caption text-grey" },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.preview.description) + " "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }