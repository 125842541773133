<!--
목적 : 시설팝업 컴포넌트
 ** 범위를 크게 잡아 사용바람
-->
<template>
  <div class="fix-height">
    <q-field
      ref="customFacility"
      class="customFacility"
      :dense="dense"
      stack-label
      filled
      color="orange-custom"
      :label="convertLabel"
      :disable="disabled||!editable"
      :rules="!required ? null : [val => !!val || '']"
      v-model="valueText"
      >
      <template v-slot:label v-if="label">
        <div class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{convertLabel}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>
      <template v-slot:control>
        {{valueText}}
        <template v-if="detail && valueText">
          / ({{facility.facilityNo}}) / {{facility.hcfFacilityTypeName}}
        </template>
      </template>

      <template v-slot:append>
        <div class="vendor-self cursor-pointer" @click="detailPop">
          <!-- 상세 -->
          {{$label('LBL0000508')}}
        </div>
        <q-icon v-if="!disabled&&editable" name="search" @click="search" class="cursor-pointer"></q-icon>
        <q-icon v-if="!disabled&&editable" name="close" @click="reset" class="cursor-pointer"></q-icon>
      </template>
    </q-field>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import mixinCommon from './js/mixin-common'
export default {
  name: 'c-facility',
  mixins: [mixinCommon],
  props: {
    name: {
      type: String,
      default: 'field',
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: { // 사용자의 id를 받아 처리
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'LBL0000557', // 시설
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'edit', // edit 쓰기 search 읽기
    },
    placeholder: {
      type: String,
      default: '',
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    plantCd: {
      type: String,
      default: '', // 10:상위공정, 20: 단위공정
    },
    detail: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      vValue: null,
      valueText: '',
      facility: {
        plantCd: '',  // 사업장코드
        facilityCd: '',  // 시설 코드
        facilityNo: '', // 시설 관리번호
        facilityName: '',  // 시설명
        processCd: '',  // 공정코드
        hcfFacilityTypeCd: null,  // 시설유형 코드
        hcfInspectionCycleCd: null,  // 정기검사주기 코드
        lastRemunerationDate: '',  // 최종보수일
        nextInspectionDate: '',  // 차기검사일
        lastSelfCheckDate: '',  // 최종자체점검일
        nextSelfCheckDate: '',  // 차기자체점검일

        installationPlace: '',  // 설치장소
        annualHandling: null,  // 연간취급량(톤/년)
        facilityCapacity: null,  // 시설용량(㎥)
        installationDate: '',  // 설치일
        disasterPreventionEquipment: '',  // 방재설비
        remarks: '',  // 비고
        modeOfTransport: '',  // 운송방식
        transportMethod: '',  // 운반방식
        tankSpecifications: null,  // 탱크 사양(재질 및 용량(㎥, ton)등)
        carryingCapacity: null,  // 운반용량(㎥, ton)
        distance: null,  // 거리(km)
        pipeMaterial: '',  // 배관재질

        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        hazardChems: [], // 빈도
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '60%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
  },
  watch: {
    // todo : 부모의 v-model 변경을 감시
    value: {
      handler: function () {
        this.vValue = this.value;
        this.setValueText();
      },
      deep: true,
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeMount() {
    this.vValue = this.value;
    this.setValueText();
  },
  mounted() {
    if (!this.disabled) {
      this.$refs['customFacility'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  beforeDestroy() {
    if (!this.disabled) {
      this.$refs['customFacility'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  /* methods */
  methods: {
    /**
     * v-model로 받은 값을 통해 사용자 정보 조회
     *
     * type에 따라 valueText에 표시되는 값 변경
     */
    setValueText() {
      if (this.vValue) {
        this.$http.url = this.$format(selectConfig.env.facility.get.url, this.vValue);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.valueText = _result.data.facilityName;
          this.$_.extend(this.facility, _result.data)
          this.$emit('facilityInfo', this.facility);
        },);
      } else {
        this.valueText = '';
        Object.assign(this.$data.facility, this.$options.data().facility);
        this.$emit('facilityInfo', null);
      }
    },
    search() {
      this.popupOptions.title = 'LBL0000558'; // 시설 검색
      this.popupOptions.param = {
        type: 'single',
        plantCd: this.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/env/hcf/hazardFacilityPop.vue'}`);
      this.popupOptions.width = '65%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.valueText = data[0].facilityName;
        this.$emit('dataChange', data[0]);
        this.$emit('input', data[0].facilityCd);
      }
    },
    reset() {
      this.valueText = '';
      this.$emit('input', null);
      this.$emit('dataChange', null);
    },
    detailPop() {
      if (this.facility.facilityCd) {
        this.popupOptions.title = 'LBL0000559'; // 시설 상세
        this.popupOptions.param = {
          facilityCd: this.facility.facilityCd,
          search: true,
        };
        this.popupOptions.target = () => import(`${'@/pages/env/hcf/hazardFacilityDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeFacilityDetailPopup;
      }
    },
    closeFacilityDetailPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  },
};
</script>
<style lang="sass">
.customFacility
  .q-field__control-container
    padding-bottom: 2px !important
  padding-bottom: 20px !important
  .q-field__native.row
    padding-left: 3px
    font-size: 0.95em
    padding-top: 4px !important
  .q-field__native
    padding-bottom: 0px !important
    cursor: pointer
  .q-field__label
    margin-left: 5px
  .material-icons
    font-size: 0.8rem
  .dateCloseIcon
    font-size: 0.8rem !important
    display: none
.customFacility:hover
  .dateCloseIcon
    display: block
.customFacility.q-field--dense .q-field__control, .customFacility.q-field--dense .q-field__marginal
  height: 33px !important
  min-height: 31px !important
  height: min-content !important
.customFacility.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px
.customchipdept.q-chip
  margin-top: -3px !important
  margin-right: 3px !important
  .q-chip__content
    font-size: 0.95em !important


.q-td
  .customFacility
    padding-bottom: 0px !important
</style>