/* eslint-disable */
const getters = {
  language: state => state.app.language,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  user: state => state.user.user,
  auths: state => state.user.auths,
  depts: state => state.user.depts,
  vendors: state => state.user.vendors,
  roles: state => state.user.roles,
  deptAuthGrp: state => state.user.deptAuthGrp,
  permission_routers: state => state.permission.routers,
  addRouters: state => state.permission.addRouters,
  menuInfo: state => state.user.menu,
  langInfo: state => state.app.langInfo,
  loading: state => state.app.loading,
  serverUrl: state => state.app.serverUrl,
  // Google OTP 2차인증용 샘플
  // otpYn: state => state.user.otpYn,
  // otpSecKey: state => state.user.otpSecKey,
  // otpUrl: state => state.user.otpUrl
}
export default getters
