var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "fix-height",
      class: [_vm.counter ? "c-textarea-counter" : ""],
    },
    [
      _vm.type === "normal"
        ? _c("q-input", {
            staticClass: "customTextArea",
            attrs: {
              rows: _vm.rows,
              "bottom-slots": "",
              autogrow: _vm.autogrow,
              label: _vm.convertLabel,
              type: "textarea",
              "input-style": {
                minHeight: 23 * _vm.rows + "px",
                maxHeight: 23 * _vm.maxrows + "px",
              },
              "stack-label": "",
              counter: _vm.counter,
              maxlength: _vm.maxlength,
              dense: _vm.dense,
              prefix: _vm.prefix,
              suffix: _vm.suffix,
              readonly: _vm.readonly,
              color: "orange-custom",
              mask: _vm.mask,
              "fill-mask": "",
              filled: "",
              "hide-bottom-space": "",
              rules: !_vm.required ? null : [(val) => !!val || ""],
              disable: _vm.disabled || !_vm.editable,
            },
            on: { input: _vm.input },
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function () {
                    return [
                      _vm.label
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "row items-center all-pointer-events",
                            },
                            [
                              _c(
                                "b",
                                [
                                  !_vm.required
                                    ? _c("i", {
                                        staticClass:
                                          "pe-7s-note labelfrontIcon searchAreaLabelIcon",
                                      })
                                    : _vm._e(),
                                  _c(
                                    "font",
                                    { staticClass: "formLabelTitle" },
                                    [_vm._v(_vm._s(_vm.convertLabel))]
                                  ),
                                  _vm.required
                                    ? _c(
                                        "i",
                                        {
                                          staticClass:
                                            "material-icons labelfrontIcon text-requiredColor",
                                        },
                                        [_vm._v("check")]
                                      )
                                    : _vm._e(),
                                  _vm.isCheck
                                    ? _c("q-checkbox", {
                                        attrs: {
                                          color: "orange-custom",
                                          editable: _vm.editable,
                                          disable:
                                            _vm.disabled || !_vm.editable,
                                          label:
                                            _vm.$comm.getLangLabel(
                                              "LBL0000577"
                                            ),
                                          "true-value": "O",
                                          "false-value": "X",
                                          name: "click",
                                        },
                                        on: { input: _vm.checkboxClick },
                                        model: {
                                          value: _vm.checkValue,
                                          callback: function ($$v) {
                                            _vm.checkValue = $$v
                                          },
                                          expression: "checkValue",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
                _vm.beforeIcon && _vm.beforeIcon.length > 0
                  ? {
                      key: "before",
                      fn: function () {
                        return _vm._l(_vm.beforeIcon, function (icon, index) {
                          return _c("q-icon", {
                            key: index,
                            class: { "cursor-pointer": icon.click },
                            attrs: { name: icon.name },
                            on: {
                              click: function ($event) {
                                return _vm.iconClick(icon)
                              },
                            },
                          })
                        })
                      },
                      proxy: true,
                    }
                  : null,
                {
                  key: "prepend",
                  fn: function () {
                    return _vm._l(_vm.prependIcon, function (icon, index) {
                      return _c("q-icon", {
                        key: index,
                        class: { "cursor-pointer": icon.click },
                        attrs: { name: icon.name },
                        on: {
                          click: function ($event) {
                            return _vm.iconClick(icon)
                          },
                        },
                      })
                    })
                  },
                  proxy: true,
                },
                {
                  key: "append",
                  fn: function () {
                    return [
                      _vm.close &&
                      !_vm.disabled &&
                      _vm.editable &&
                      !_vm.readonly
                        ? _c("q-icon", {
                            staticClass: "cursor-pointer textareaCloseIcon",
                            attrs: { name: "close" },
                            on: { click: _vm.closeClick },
                          })
                        : _vm._e(),
                      _vm._l(_vm.appendIcon, function (icon, index) {
                        return _c("q-icon", {
                          key: index,
                          class: { "cursor-pointer": icon.click },
                          attrs: { name: icon.name },
                          on: {
                            click: function ($event) {
                              return _vm.iconClick(icon)
                            },
                          },
                        })
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "after",
                  fn: function () {
                    return _vm._l(_vm.afterIcon, function (icon, index) {
                      return _c("q-icon", {
                        key: index,
                        class: { "cursor-pointer": icon.click },
                        attrs: { name: icon.name },
                        on: {
                          click: function ($event) {
                            return _vm.iconClick(icon)
                          },
                        },
                      })
                    })
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
            model: {
              value: _vm.vValue,
              callback: function ($$v) {
                _vm.vValue = $$v
              },
              expression: "vValue",
            },
          })
        : _c(
            "q-card",
            {
              staticClass: "custom-textarea-editor",
              staticStyle: { width: "100%" },
              attrs: { flat: "" },
            },
            [
              _c(
                "q-card-section",
                { staticStyle: { top: "-14px" }, attrs: { horizontal: "" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "q-field__label no-pointer-events ellipsis",
                      staticStyle: {
                        "line-height": "15px",
                        "padding-bottom": "1px",
                        "font-size": "12px",
                        "padding-left": "8px",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "row items-center all-pointer-events" },
                        [
                          _c(
                            "b",
                            { staticClass: "custom-textarea-label" },
                            [
                              _c("font", { staticClass: "formLabelTitle2" }, [
                                _vm._v(_vm._s(_vm.convertLabel)),
                              ]),
                              _vm._v("  "),
                              _vm.required
                                ? _c(
                                    "em",
                                    {
                                      staticClass:
                                        "q-px-sm bg-deep-orange text-white rounded-borders",
                                    },
                                    [_vm._v("check")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "q-card-section",
                {
                  class: {
                    "cursor-pointer": !_vm.disabled && _vm.editable,
                    "q-py-none": true,
                  },
                  style: {
                    paddingBottom: "10px",
                    height: "auto",
                    minHeight: _vm.editheight + "rem",
                    top: "0px",
                  },
                },
                [
                  _c("q-editor", {
                    ref: "editor",
                    attrs: {
                      "min-height": _vm.editheightrem,
                      autofocus: "",
                      disable: _vm.disabled || !_vm.editable,
                      definitions: _vm.definitions,
                      toolbar: [
                        [
                          {
                            label: _vm.$comm.getLangLabel("LBL0000702"),
                            fixedLabel: true,
                            list: "only-icons",
                            options: ["left", "center", "right", "justify"],
                          },
                        ],
                        ["bold", "italic", "strike", "underline"],
                        ["token"],
                        ["hr"],
                        [
                          {
                            label: _vm.$comm.getLangLabel("LBL0000703"),
                            fixedLabel: true,
                            fixedIcon: true,
                            list: "no-icons",
                            options: [
                              "size-1",
                              "size-2",
                              "size-3",
                              "size-4",
                              "size-5",
                              "size-6",
                              "size-7",
                            ],
                          },
                        ],
                        ["undo", "redo"],
                        ["insert_img"],
                        ["viewsource"],
                        ["fullscreen"],
                      ],
                    },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        $event.stopPropagation()
                      },
                      input: _vm.input,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "token",
                        fn: function () {
                          return [
                            _c(
                              "q-btn-dropdown",
                              {
                                ref: "token",
                                attrs: {
                                  dense: "",
                                  "no-caps": "",
                                  "no-wrap": "",
                                  unelevated: "",
                                  color: "white",
                                  "text-color": "primary",
                                  label: "Font Color",
                                  size: "sm",
                                },
                              },
                              [
                                _c(
                                  "q-list",
                                  [
                                    _c(
                                      "q-item",
                                      {
                                        attrs: { tag: "label", clickable: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.color(
                                              "backColor",
                                              _vm.highlight
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "q-item-section",
                                          { attrs: { side: "" } },
                                          [
                                            _c(
                                              "q-icon",
                                              { attrs: { name: "highlight" } },
                                              [
                                                _c("q-tooltip", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$label("LBL0000704")
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("q-color", {
                                              staticClass: "my-picker",
                                              attrs: {
                                                "default-view": "palette",
                                                "no-header": "",
                                                "no-footer": "",
                                              },
                                              model: {
                                                value: _vm.highlight,
                                                callback: function ($$v) {
                                                  _vm.highlight = $$v
                                                },
                                                expression: "highlight",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      {
                                        attrs: { tag: "label", clickable: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.color(
                                              "foreColor",
                                              _vm.foreColor
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "q-item-section",
                                          { attrs: { side: "" } },
                                          [
                                            _c(
                                              "q-icon",
                                              {
                                                attrs: { name: "format_paint" },
                                              },
                                              [
                                                _c("q-tooltip", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$label("LBL0000705")
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("q-color", {
                                              staticClass: "my-picker",
                                              attrs: {
                                                "no-header": "",
                                                "no-footer": "",
                                                "default-view": "palette",
                                              },
                                              model: {
                                                value: _vm.foreColor,
                                                callback: function ($$v) {
                                                  _vm.foreColor = $$v
                                                },
                                                expression: "foreColor",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.vValue,
                      callback: function ($$v) {
                        _vm.vValue = $$v
                      },
                      expression: "vValue",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }