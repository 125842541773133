<!--
목적 : 수급업체 컴포넌트
-->
<template>
  <div class="fix-height">
    <q-field
      ref="customDangerArea"
      class="customDangerArea"
      :dense="dense"
      stack-label
      filled
      color="orange-custom"
      :label="convertLabel"
      :disable="disabled||!editable"
      :rules="!required ? null : [val => !!val || '']"
      v-model="valueText"
      >
      <template v-slot:label v-if="label">
        <div class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{convertLabel}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>
      <template v-slot:control>
        {{valueText}}
      </template>

      <template v-slot:append>
        <!-- 자체 -->
        <q-icon v-if="!disabled&&editable" name="search" @click="search" class="cursor-pointer"></q-icon>
        <q-icon v-if="!disabled&&editable" name="close" @click="reset" class="cursor-pointer"></q-icon>
      </template>
    </q-field>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import mixinCommon from './js/mixin-common'
import selectConfig from '@/js/selectConfig';
export default {
  name: 'CDangerArea',
  mixins: [mixinCommon],
  props: {
    name: {
      type: String,
      default: 'field',
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: { // 사용자의 id를 받아 처리
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'edit', // search, edit
    },
    placeholder: {
      type: String,
      default: '',
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    plantCd: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      vValue: null,
      valueText: '',
      detailUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
  },
  watch: {
    // todo : 부모의 v-model 변경을 감시
    value: {
      handler: function () {
        this.vValue = this.value;
        this.setValueText();
      },
      deep: true,
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeMount() {
    this.vValue = this.value;
    this.detailUrl = selectConfig.chm.danger.master.get.url;
    this.setValueText();
  },
  mounted() {
    if (!this.disabled) {
      this.$refs['customDangerArea'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  beforeDestroy() {
    if (!this.disabled) {
      this.$refs['customDangerArea'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  /* methods */
  methods: {
    /**
     * v-model로 받은 값을 통해 사용자 정보 조회
     *
     * type에 따라 valueText에 표시되는 값 변경
     */
    setValueText() {
      if (this.vValue) {
        this.$http.url = this.$format(this.detailUrl, this.vValue);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          if (_result.data) {
            this.valueText = _result.data.chmDangerMstName;
          } else {
            this.valueText = '';
          }
          this.$emit('detailInfo', _result.data)
        },);
      } else {
        this.valueText = '';
      }
    },
    search() {
      this.popupOptions.title = '위험물 저장소 검색'; // 위험물 저장소 검색
      this.popupOptions.param = {
        type: 'single',
        plantCd: this.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/chm/danger/dangerMasterPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let beforeChmDangerMstId = this.$_.clone(this.vValue);
        this.$emit('dataChange', data[0].chmDangerMstId, beforeChmDangerMstId, data[0]);
        this.$emit('chmDangerMstName', data[0].chmDangerMstName);
        this.$emit('input', data[0].chmDangerMstId);
      }
    },
    reset() {
      let beforeChmDangerMstId = this.$_.clone(this.vValue);
      this.valueText = '';
      this.$emit('dataChange', null, beforeChmDangerMstId);
      this.$emit('input', null);
      this.$emit('chmDangerMstName', null);
    },
  },
};
</script>
<style lang="sass">
.customDangerArea
  .q-field__native.row
    padding-left: 3px
    font-size: 0.95em
    padding-top: 4px !important
  .q-field__native
    padding-bottom: 6px !important
    cursor: pointer
  .q-field__label
    margin-left: 5px
  .material-icons
    font-size: 0.8rem
  .dateCloseIcon
    font-size: 0.8rem !important
    display: none
.customDangerArea:hover
  .dateCloseIcon
    display: block
.customDangerArea.q-field--dense .q-field__control, .customDangerArea.q-field--dense .q-field__marginal
  height: 33px !important
  min-height: 33px !important
.customDangerArea.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px
</style>