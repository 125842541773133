<template>
  <div class="fix-height">
    <q-field
      ref="customField"
      class="customDate custom-field"
      :dense="dense"
      stack-label
      filled
      color="orange-custom"
      :label="convertLabel"
      :disable="disabled||!editable"
      :rules="!required ? null : [val => !!val || '']"
      v-model="valueText"
      >
      <template v-slot:label v-if="label">
        <div class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{convertLabel}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>
      <template v-slot:control @click.stop="search" class="cursor-pointer">
        {{valueText}}
      </template>

      <template v-slot:append>
        <q-icon v-if="!disabled&&editable" name="search" @click="search" class="cursor-pointer"></q-icon>
        <q-icon v-if="!disabled&&editable" name="close" @click="reset" class="cursor-pointer"></q-icon>
      </template>
    </q-field>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import mixinCommon from './js/mixin-common'
export default {
  /* attributes: name, components, props, data */
  name: 'c-moc',
  mixins: [mixinCommon],
  props: {
    name: {
      type: String,
      default: 'moc',
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: { // 사용자의 id를 받아 처리
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    plantCd: {
      type: String,
      default: '',
    },
    isSubmit: {
      type: String,
      default: '',
    },
    document: {
      type: Object,
    },
    documentId: {
      type: String,
      default: '',
    },
    documentTitle: {
      type: String,
      default: '',
    },
    mocRelatedTaskCd: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      moc: {
        sopMocId: '',  // MOC 일련번호
        mocNo: '',  // moc 관리번호
        plantCd: '',  // 사업장 코드
        initiativeUserId: '',  // 발의자 ID
        initiativeUserName: '',  // 발의자 명
        initiativeDeptCd: '',  // 발의 부서 코드
        initiativeDeptName: '',  // 발의 부서명
        mocTitle: '',  // 변경관리 제목
        mocSummary: '',  // 변경 개요
        mocTypeCd: '',  // 변경구분_공통코드(정상/비상/임시)
        mocTypeName: '',  // 변경구분명(정상/비상/임시)
        mocWriteLevelCd: null,  // 변경등급_작성
        mocConfirmLevelCd: null,  // 변경등급_검토
        processSatefySecureMeasures: '',  // 공정안전 확보 대책
        technicalBasisProcessDesign: '',  // 변경계획에 대한 공정 및 설계의 기술근거
        safetyReliabilityImprovementEffect: '',  // 안전성에 필요한 사항 및 신뢰성 향상 효과
        leaderOpinion: '',  // 팀장의견
        relatedLaws: '',  // 관련 볍령_텍스트
        processRiskAssessFlag: '',  // 공정위험성 평가 여부
        jobRiskAssessFlag: '',  // 작업위헝성 평가 여부
        mocPeriod: [],
        mocStartDate: '',  // 변경 시작일
        mocEndDate: '',  // 변경 종료일
        mocStepCd: '',  // 변경관리 단계
        mocActionDeptCd: '',  // 변경실행 부서코드
        mocActionResponsibilityUserId: '',  // 변경실행 책임자 ID
        mocCompleteCheckUserId: '',  // 변경완료 확인자 ID
        mocCompleteCheckDeptCd: '',  // 변경완료 확인 부서 코드
        mocCompleteOpinion: '',  // 변경완료 의견
        mocBenefitCds: '',  // 변경관리 기대효과_복수
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
      },
      valueText: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
      setEvent: false,
      detailUrl: '',
      saveUrl: '',
    };
  },
  computed: {
  },
  watch: {
    // todo : 부모의 v-model 변경을 감시
    value: {
      handler: function () {
        this.getDetail();
      },
      deep: true,
    },
    disabled() {
      if (!this.setEvent && !this.disabled) {
        this.setEvent = true;
        this.$refs['customField'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.openDetail);
      }
    },
    isSubmit() {
      this.saveRelatedWork();
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeMount() {
    this.init();
  },
  mounted() {
    if (!this.disabled) {
      this.setEvent = true;
      this.$refs['customField'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  beforeDestroy() {
    if (!this.disabled) {
      this.$refs['customField'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  /* methods */
  methods: {
    init() {
      this.detailUrl = selectConfig.sop.moc.change.get.url
      this.saveUrl = transactionConfig.sop.moc.relatedWorkDocument.save.url
      this.getDetail();
    },
    getDetail() {
      if (this.value) {
        this.$http.url = this.$format(this.detailUrl, this.value);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.moc, _result.data);
          // 변경 타입 / 관리번호
          if (this.value && this.moc) {
            this.valueText = this.moc.mocTitle + ' / ' + this.moc.mocTypeName + ' / ' + this.moc.mocNo
          }
        },);
      } else {
        this.reset();
      }
    },
    search() {
      this.popupOptions.title = 'LBL0000562'; // MOC 검색
      this.popupOptions.param = {
        type: 'single',
        plantCd: this.plantCd ? this.plantCd : this.$store.getters.user.plantCd,
        mocRelatedTaskCd: this.mocRelatedTaskCd
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/moc/mocPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.moc = data[0]
        this.valueText = data[0].mocTitle + ' / ' + data[0].mocTypeName + ' / ' + data[0].mocNo
        this.$emit('name', this.valueText);
        this.$emit('input', data[0].sopMocId);
        this.$emit('dataChange', data[0].sopMocId);
      }
    },
    openDetail() {
      if (this.value && this.moc) {
        this.popupOptions.title = 'LBL0000563'; // MOC 상세
        this.popupOptions.param = {
          sopMocId: this.value,
          mocTypeCd: this.moc.mocTypeCd,
          mocStepCd: this.moc.mocStepCd,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/moc/mocDetail.vue'}`);
        this.popupOptions.isFull = true;
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeDetailPopup;
      }
    },
    closeDetailPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    reset() {
      this.valueText = null
      this.$emit('input', null);
      this.$emit('name', '');
      this.$emit('dataChange', null);
    },
    saveRelatedWork() {
      this.$http.url = this.saveUrl;
      this.$http.type = 'PUT';
      this.$http.param = {
        sopChangeRelatedWorkDocumentId: '',  // 관련업무 일련번호
        sopChangeRelatedWorkId: '',  // 관련업무 일련번호
        documentId: this.documentId,  // 문서 일련번호
        documentTitle: this.document[this.documentTitle],  // 문서 제목
        documentRegUserId: '',  // 등록자
        documentRegDt: (this.document.regDt ? this.document.regDt : this.$comm.getToday()),  // 등록일
        sopMocId: this.value,
        mocRelatedTaskCd: this.mocRelatedTaskCd,
      };
      this.$http.request(() => {
      },);

    }
  },
};
</script>
<style lang="sass">
.customDate
  .q-field__before
    padding-top: 5px
.custom-text-before
  font-size:14px
  border: 1px solid rgba(0, 0, 0, 0.15) !important
  border-right: 0  !important
  border-radius: 4px 0 0 0
  line-height: 31px
  height: 33px !important
  min-height: 33px !important

.custom-field
  .q-field__native
    cursor: pointer
  </style>