<!--
목적 : 자재팝업 컴포넌트
-->
<template>
  <div class="fix-height">
    <q-field
      ref="customMaterial"
      class="customMaterial"
      :dense="dense"
      stack-label
      filled
      color="orange-custom"
      :label="convertLabel"
      :disable="disabled||!editable"
      :rules="!required ? null : [myRule]"
      v-model="valueText"
      >
      <template v-slot:label v-if="label">
        <div class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{convertLabel}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>
      <template v-slot:control>
        <template v-if="!editing">
          {{valueText}}
        </template>
        <template v-else>
          <div v-if="isWrite" class="col-2 input-material-div">
            <q-chip outline square size="6px" color="primary" text-color="white">
              TEMP
            </q-chip>
          </div>
          <input 
            class="custom-editing-input" 
            :class="isWrite ? 'col-2' : 'col-4'"
            ref="custom-editing-input-first"
            type="text"
            maxlength="10"
            :disabled="!isWrite"
            v-model="valueText1"
            @input="datachange('1')" />
          <div class="col-1 input-material-div">/</div>
          <input 
            :class="type==='codenameen' ? 'col-3' : 'col-7'"
            class="custom-editing-input" 
            ref="custom-editing-input"
            type="text"
            :disabled="!isWrite"
            v-model="valueText2"
            @input="datachange('2')" />
          <div v-if="type==='codenameen'" class="col-1 input-material-div">/</div>
          <input 
            v-if="type==='codenameen'" 
            class="col-3 custom-editing-input" 
            ref="custom-editing-input"
            type="text"
            :disabled="!isWrite"
            v-model="valueText3"
            @input="datachange('3')" />
        </template>
      </template>

      <template v-slot:append>
        <q-icon v-if="!disabled&&editable&&isWrite" name="edit" @click="edit" 
          class="cursor-pointer"
          :class="[editing ? 'text-red' : '']"></q-icon>
        <q-icon v-if="!disabled&&editable" name="search" @click="search" class="cursor-pointer"></q-icon>
        <q-icon v-if="!disabled&&editable" name="close" @click="reset" class="cursor-pointer"></q-icon>
      </template>
    </q-field>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import mixinCommon from './js/mixin-common'
const EDIT_PREFIX = 'TEMP';
export default {
  name: 'c-material',
  mixins: [mixinCommon],
  props: {
    name: {
      type: String,
      default: 'field',
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: { // 사용자의 id를 받아 처리
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'name', // codename
    },
    plantCd: {
      type: String,
      default: '', //
    },
    placeholder: {
      type: String,
      default: '',
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: String,
      default: 'single',
    }, 
    isWrite: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
    },
    materialName: {
      type: String,
      default: 'materialName',
    },
    materialNameEn: {
      type: String,
      default: 'materialNameEn',
    },
    reviewCheck: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vValue: null,
      valueText: '',
      valueText1: '',
      valueText2: '',
      valueText3: '',
      editing: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '60%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
  },
  watch: {
    // todo : 부모의 v-model 변경을 감시
    value: {
      handler: function () {
        this.vValue = this.value;
        if (this.vValue && this.vValue.indexOf(EDIT_PREFIX) > -1) {
          this.editing = true;
        } else {
          this.editing = false;
        }
        this.setValueText();
      },
      deep: true,
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeMount() {
    this.vValue = this.value;
    if (!this.editing && this.vValue && this.vValue.indexOf(EDIT_PREFIX) > -1) {
      this.editing = true;
    }
    this.setValueText();
  },
  mounted() {
    if (!this.disabled) {
      this.$refs['customMaterial'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  beforeDestroy() {
    if (!this.disabled) {
      this.$refs['customMaterial'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  /* methods */
  methods: {
    /**
     * v-model로 받은 값을 통해 사용자 정보 조회
     *
     * type에 따라 valueText에 표시되는 값 변경
     */
    setValueText() {
      if (this.vValue) {
        if (!this.editing) {
          this.$http.url = this.$format(selectConfig.mdm.mam.material.get.url, this.vValue);
          this.$http.type = 'GET'; 
          this.$http.request((_result) => { 
            if (this.type == 'name') {
              this.valueText = _result.data.materialName;
            } else if (this.type == 'codename') {
              this.valueText = _result.data.materialCd + ' / ' +_result.data.materialName;
            } else if (this.type == 'codenameen') {
              this.valueText = _result.data.materialCd + ' / ' +_result.data.materialName + ' / ' +_result.data.materialNameEn;
            }
          },);
        } else {
          let text1 = this.$_.clone(this.vValue) || '';
          if (text1 && text1.indexOf(EDIT_PREFIX) === 0) {
            text1 = this.$_.replace(text1, EDIT_PREFIX, '');
          }
          this.valueText1 = text1;
          this.valueText2 = this.data[this.materialName] || '';
          this.valueText3 = this.data[this.materialNameEn] || '';
          this.valueText = this.valueText1 + this.valueText2 + this.valueText3;
        }
      } else {
        if (this.editing) {
          this.valueText1 = '';
          this.valueText2 = '';
          this.valueText3 = '';
          this.myRule();
        }
        this.valueText = '';
      }
    },
    search() {
      this.popupOptions.title = '자재 검색'; 
      this.popupOptions.param = {
        type: this.multiple,
        plantCd: this.plantCd,
        reviewCheck: this.reviewCheck,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/material/materialPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (this.type == 'name') {
          this.valueText =data[0].materialName;
          this.$emit('name', data[0].materialName);
        } else if (this.type == 'codename') {
          this.valueText = data[0].materialNo + ' / ' +data[0].materialName;
          this.$set(this.data, this.materialName, data[0].materialName)
          this.$emit('name', data[0].materialNo + ' / ' +data[0].materialName);
        } else if (this.type == 'codenameen') {
          this.valueText = data[0].materialNo + ' / ' + data[0].materialName + ' / ' + data[0].materialNameEn;
          this.$set(this.data, this.materialName, data[0].materialName)
          this.$set(this.data, this.materialNameEn, data[0].materialNameEn)
          this.$emit('name', data[0].materialNo + ' / ' +data[0].materialName + ' / ' + data[0].materialNameEn);
        }
        this.$emit('input', data[0].materialCd);
        this.$emit('datachange', data[0]);
      }
    },
    reset() {
      this.valueText = '';
      this.valueText1 = '';
      this.valueText2 = '';
      this.valueText3 = '';
      this.$set(this.data, this.materialName, null)
      this.$set(this.data, this.materialNameEn, null)
      this.$emit('input', null);
      this.$emit('datachange', null);
    },
    edit() {
      /**
       * key-in
       */
      if (!this.editing) {
        this.editing = true;
        setTimeout(() => {
          this.$refs['custom-editing-input-first'].focus();
        }, 50);
        this.$emit('input', 'TEMP');
      } else {
        this.$set(this.data, this.materialName, null)
        this.$set(this.data, this.materialNameEn, null)
        this.editing = false;
        this.$emit('input', null);
      }
      this.$emit('datachange', null);
      this.valueText = '';
      this.valueText1 = '';
      this.valueText2 = '';
      this.valueText3 = '';
    },
    datachange(type) {
      if (type === '1') {
        this.$emit('input', (EDIT_PREFIX + this.valueText1))
        // this.$set(this.data, 'materialCd', this.valueText1)
      } else if (type === '2') {
        this.$set(this.data, this.materialName, this.valueText2)
      } else {
        this.$set(this.data, this.materialNameEn, this.valueText3)
      }
      this.valueText = this.valueText1 + this.valueText2 + this.valueText3;
      this.$emit('datachange', this.data);
    },
    myRule(val) {
      if (!this.editing) {
        if (!(val)) {
          return ''
        }
      } else {
        if (!(this.valueText1 && this.valueText2 && this.valueText3)) {
        // if (!(this.valueText1)) {
          return ''
        }
      }
    }
  },
};
</script>
<style lang="sass">
.customMaterial
  .q-field__native.row
    padding-left: 3px
    font-size: 0.95em
    padding-top: 4px !important
  .q-field__native
    padding-bottom: 6px !important
  .q-field__label
    margin-left: 5px
  .material-icons
    font-size: 0.8rem
  .dateCloseIcon
    font-size: 0.8rem !important
    display: none
.customMaterial:hover
  .dateCloseIcon
    display: block
.customMaterial.q-field--dense .q-field__control, .customMaterial.q-field--dense .q-field__marginal
  height: 33px !important
  min-height: 33px !important
.customMaterial.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px

.customMaterial
  .q-field__native
    cursor: pointer
    .q-field__control
      border:0 !important
      padding:0px !important
    .custom-editing-input
      line-height: 18px !important
      padding: 0 !important
      border: 0
    .custom-editing-input:focus
      border: 0 !important
      outline: none

.input-material-div
  padding: 0 !important
  margin-top: -3px
</style>