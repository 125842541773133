<!--
목적 : 장소 컴포넌트
-->
<template>
  <div class="fix-height">
    <q-field
      ref="customUtv"
      class="customUtv"
      :dense="dense"
      stack-label
      filled
      color="orange-custom"
      :label="convertLabel"
      :disable="disabled||!editable"
      :rules="!required ? null : [val => !!val || '']"
      v-model="valueText"
      >
      <template v-slot:label v-if="label">
        <div class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{convertLabel}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>
      <template v-slot:control>
        <template v-if="!isMultiple">
          <template>
            {{valueText}}
          </template>
        </template>
        <template v-else>
          <q-chip
            v-for="(utv, index) in utvs"
            :key="index"
            outline square 
            :removable="!disabled&&editable"
            color="blue"
            text-color="white"
            class="q-ma-none customchipdept"
            @remove="remove(utv)">
            {{utv.utvName}}
          </q-chip>
        </template>
      </template>

      <template v-slot:append>
        <q-icon v-if="!disabled&&editable" name="search" @click="search" class="cursor-pointer"></q-icon>
        <q-icon v-if="!disabled&&editable" name="close" @click="reset" class="cursor-pointer"></q-icon>
      </template>
    </q-field>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import mixinCommon from './js/mixin-common'
export default {
  name: 'CFireFightingUtv',
  mixins: [mixinCommon],
  props: {
    name: {
      type: String,
      default: 'field',
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: { // 사용자의 id를 받아 처리
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'UTV 소방차',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'edit',
    },
    multiple: {
      type: String,
      default: 'single',
    },
    placeholder: {
      type: String,
      default: '',
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    plantCd: {
      type: String,
      default: '',
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
    },
  },
  data() {
    return {
      vValue: null,
      valueText: '',
      utvs: [],
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '40%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
  },
  watch: {
    // todo : 부모의 v-model 변경을 감시
    value: {
      handler: function () {
        if (this.value) {
          this.vValue = this.value;
          this.getUtvName(this.value);
        } else {
          this.$emit('input', null);
          this.utvs = [];
          this.valueText = '';
        }
      },
      deep: true,
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeMount() {
    this.vValue = this.value;
    this.listUrl = selectConfig.fft.fireFightingUtv.list.url;
    if (this.value) {
      this.getUtvName(this.value);
    } else {
      this.valueText = '';
    }
  },
  mounted() {
    if (!this.disabled) {
      this.$refs['customUtv'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  beforeDestroy() {
    if (!this.disabled) {
      this.$refs['customUtv'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  /* methods */
  methods: {
    getUtvName(_id) {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        sopFireFightingUtvIds: this.isMultiple ? _id : null,
        sopFireFightingUtvId: !this.isMultiple ? _id : null,
      };
      this.$http.request((_result) => {
        if (_result.data && _result.data.length > 0) {
          if (!this.isMultiple) {
            this.valueText = _result.data[0].utvName;
          } else {
            this.utvs = _result.data
            this.valueText = this.$_.map(this.utvs, 'utvName').join(',');
          }
        } else {
          this.utvs = [];
          this.valueText = '';
        }
      },);
    },
    search() {
      this.popupOptions.title = 'UTV 소방차 검색';
      this.popupOptions.param = {
        type: this.isMultiple ? 'multiple' : 'single',
      };
      this.popupOptions.target = () => import(`${'@/pages/fft/utv/fireFightingUtvPop.vue'}`);
      this.popupOptions.width = '65%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (!this.isMultiple) {
          this.$emit('input', data[0].sopFireFightingUtvId);
          this.$emit('dataChange', data[0], 'ADD');
        } else {
          this.$_.forEach(data, utv => {
            if (this.$_.findIndex(this.utvs, { sopFireFightingUtvId: utv.sopFireFightingUtvId }) === -1) {
              this.utvs.push(utv)
            }
          })
          this.$emit('input', this.$_.map(this.utvs, 'sopFireFightingUtvId').join(','));
          this.$emit('dataChange', this.utvs, 'ADD');
        }
      }
    },
    reset() {
      this.valueText = '';
      this.$emit('input', null);
      this.$emit('dataChange', null, 'DELETE');
    },
    remove(utv) {
      // 문자열로 들어온 경우 ** 배열로 들어온 경우는 고려하지 않음
      this.utvs = this.$_.reject(this.utvs, { sopFireFightingUtvId: utv.sopFireFightingUtvId });

      this.$emit('input', this.$_.map(this.utvs, 'sopFireFightingUtvId').join(','));
      this.$emit('dataChange', this.utvs, 'DELETE');
    },
  },
};
</script>
<style lang="sass">
.customUtv
  padding-bottom: 20px !important
  .q-field__native.row
    padding-left: 3px
    font-size: 0.95em
    padding-top: 4px !important
  .q-field__native
    padding-bottom: 6px !important
    cursor: pointer
  .q-field__label
    margin-left: 5px
  .material-icons
    font-size: 0.8rem
  .dateCloseIcon
    font-size: 0.8rem !important
    display: none
  .q-field__native.row
    padding-bottom: 0px !important
.customUtv:hover
  .dateCloseIcon
    display: block
.customUtv.q-field--dense .q-field__control, .customUtv.q-field--dense .q-field__marginal
  height: min-content !important
  min-height: 30px !important
.customUtv.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px
</style>